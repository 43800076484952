import KEYS from '../constants/keys';

/**
 * Check if a HTMLElement is visible
 * @param {HTMLElement} element - to check
 * @returns {boolean}
 */
export const isVisible = function(element: HTMLElement): boolean {
  return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
};

export const classUtils = {
  /**
   * Add a class to a HTMLElement
   * @param {HTMLElement} element - to add class to
   * @param {string} className - className to add
   */
  addClass(element: HTMLElement, className: string): void {
    if (!this.hasClass(element, className)) {
      const currentClass = element.getAttribute('class');
      const newClass = currentClass ? `${currentClass} ${className}` : className;
      element.setAttribute('class', newClass);
    }
  },

  /**
   * Get an array containing the classes of an HTMLElement
   * @param {HTMLElement} element - element to extract classes from
   */
  getClasses(element: HTMLElement): string[] {
    const elementClass = element.getAttribute('class') || '';

    return elementClass.split(/\s+/).filter(className => className !== '');
  },

  /**
   * Check if a HTMLElement has a specific class
   * @param {HTMLElement} element - to add class to
   * @param {string} className - className to add
   * @returns {boolean}
   */
  hasClass(element: HTMLElement, className: string): boolean {
    const elementClass = element.getAttribute('class');

    return new RegExp(`(\\s|^)${className}(\\s|$)`).test(elementClass);
  },

  /**
   * Remove a class from a HTMLElement
   * @param {HTMLElement} element - to remove class from
   * @param {string} className - className to remove
   */
  removeClass(element: HTMLElement, className: string): void {
    if (this.hasClass(element, className)) {
      const classRegExp = new RegExp(`(\\s|^)${className}(\\s|$)`);
      const currentClass = element.getAttribute('class');
      element.setAttribute('class', currentClass.replace(classRegExp, ' ').trim());
    }
  },

  /**
   * Toggle a class on an HTMLElement
   * @param {HTMLElement} element - to toggle class on
   * @param {string} className - className to toggle
   */
  toggleClass(element: HTMLElement, className: string): void {
    if (this.hasClass(element, className)) {
      this.removeClass(element, className);
    } else {
      this.addClass(element, className);
    }
  }
};

export const findParent = function(element: HTMLElement, className: string): HTMLElement {
  while (!classUtils.hasClass(element, className)) {
    if (element.parentElement === null) {
      return undefined;
    }

    element = element.parentElement;
  }

  return element;
};

export const textUtils = {
  replaceNewlinesWithBreaks(text: string): string {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }
};

export const getKeyCodeFromEvent = function(event: KeyboardEvent): number {
  return event.keyCode || event.which;
};

export const isEscapeKeyPressed = function(event: KeyboardEvent): boolean {
  return getKeyCodeFromEvent(event) === KEYS.ESC;
};

export const isEnterKeyPressed = function(event: KeyboardEvent): boolean {
  return getKeyCodeFromEvent(event) === KEYS.ENTER;
};
