import { Locale } from './locale';

const locale: Locale = {
  DATETIME_FORMATS: {
    AMPMS: ['a.m.', 'p.m.'],
    DAY: ['s\u00f8ndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'l\u00f8rdag'],
    ERANAMES: ['f.Kr.', 'e.Kr.'],
    ERAS: ['f.Kr.', 'e.Kr.'],
    FIRSTDAYOFWEEK: 0,
    MONTH: [
      'januar',
      'februar',
      'mars',
      'april',
      'mai',
      'juni',
      'juli',
      'august',
      'september',
      'oktober',
      'november',
      'desember'
    ],
    SHORTDAY: ['s\u00f8n.', 'man.', 'tir.', 'ons.', 'tor.', 'fre.', 'l\u00f8r.'],
    SHORTMONTH: ['jan.', 'feb.', 'mar.', 'apr.', 'mai', 'jun.', 'jul.', 'aug.', 'sep.', 'okt.', 'nov.', 'des.'],
    WEEKENDRANGE: [5, 6],
    fullDate: 'EEEE d. MMMM y',
    longDate: 'd. MMMM y',
    medium: 'd. MMM y HH.mm.ss',
    mediumDate: 'd. MMM y',
    mediumTime: 'HH.mm.ss',
    short: 'dd.MM.y HH.mm',
    shortDate: 'dd.MM.y',
    shortTime: 'HH.mm'
  },
  NUMBER_FORMATS: {
    CURRENCY_SYM: 'kr',
    DECIMAL_SEP: ',',
    GROUP_SEP: '\u00a0',
    PATTERNS: [
      {
        gSize: 3,
        lgSize: 3,
        maxFrac: 3,
        minFrac: 0,
        minInt: 1,
        negPre: '-',
        negSuf: '',
        posPre: '',
        posSuf: ''
      },
      {
        gSize: 3,
        lgSize: 3,
        maxFrac: 2,
        minFrac: 2,
        minInt: 1,
        negPre: '-',
        negSuf: '',
        posPre: '',
        posSuf: ''
      }
    ]
  },
  id: 'nb'
};

export default locale;
