/**
 * Code in this module is heavily influenced by Dispatcher module in Facebook's flux project
 * https://github.com/facebook/flux/blob/master/src/Dispatcher.js
 */
/**
 * Dispatcher is used to broadcast payloads to registered callbacks. This is
 * different from generic pub-sub systems in two ways:
 *
 *   1) Callbacks are not subscribed to particular events. Every payload is
 *      dispatched to every registered callback.
 *   2) Callbacks can be deferred in whole or part until other callbacks have
 *      been executed.
 *
 */
import { invariant } from '../services/logService';

interface DispatcherAction {
  isHandled: Object;
  isPending: Object;
  payload: Object;
}

const CALLBACK_ID_PREFIX = 'ID_';
const callbacks = {};
const actionQueue = [];
let lastID = 1;
let isCurrentlyDispatching = false;
let currentAction: DispatcherAction = null;
let nestedCalls = 0;

/**
 * @description Call the callback stored with the given id. Also do some internal
 * bookkeeping.
 * @param {string} id Callback id
 */
const invokeCallback = function(id: string): void {
  currentAction.isPending[id] = true;
  callbacks[id](currentAction.payload);
  currentAction.isHandled[id] = true;
};

/**
 * @description Set up bookkeeping needed when dispatching.
 */
const startDispatching = function(payload: any): void {
  currentAction = {
    isHandled: {},
    isPending: {},
    payload
  };
  isCurrentlyDispatching = true;
};

/**
 * @description Clear bookkeeping used for dispatching.
 */
const stopDispatching = function(): void {
  currentAction = null;
  isCurrentlyDispatching = false;
};

/**
 * @description Registers a callback to be invoked with every dispatched payload. Returns
 * a token that can be used with `waitFor()`.
 * @param {function} callback Callback function
 * @return {string} Unique callback id
 */
export const register = function(callback: Function): string {
  const id = CALLBACK_ID_PREFIX + lastID++;
  callbacks[id] = callback;

  return id;
};

/**
 * @description Removes a callback based on its token.
 * @param {string} id Registered callback id
 */
export const unregister = function(id: string): void {
  invariant(callbacks[id], 'Dispatcher.unregister(...): `%s` does not map to a registered callback.', id);

  delete callbacks[id];
};

/**
 * @description Waits for the callbacks specified to be invoked before continuing execution
 * of the current callback. This method should only be used by a callback in
 * response to a dispatched payload.
 * @param {Array<string>} ids Array with callback ids
 */
export const waitFor = function(ids: string[]): void {
  invariant(isDispatching, 'Dispatcher.waitFor(...): Must be invoked while dispatching.');

  for (let ii = 0; ii < ids.length; ii++) {
    const id = ids[ii];

    if (currentAction.isPending[id]) {
      invariant(
        currentAction.isHandled[id],
        'Dispatcher.waitFor(...): Circular dependency detected while ' + 'waiting for `%s`.',
        id
      );
      continue;
    }

    invariant(callbacks[id], 'Dispatcher.waitFor(...): `%s` does not map to a registered callback.', id);
    invokeCallback(id);
  }
};

/**
 * @description Dispatches a payload to all registered callbacks.
 * @param {Object} payload Action that needs to be dispatched
 */
export const dispatch = function(payload: any): void {
  invariant(nestedCalls < 100, 'Dispatcher.dispatch(...): Too many nested dispatch calls, possible infinite loop');

  if (isDispatching()) {
    actionQueue.push(payload);

    return;
  }

  startDispatching(payload);

  try {
    for (const id in callbacks) {
      if (callbacks.hasOwnProperty(id)) {
        if (currentAction.isPending[id]) {
          continue;
        }

        invokeCallback(id);
      }
    }
  } finally {
    stopDispatching();
  }

  if (actionQueue.length > 0) {
    nestedCalls++;
    dispatch(actionQueue.shift());
  }

  nestedCalls = 0;
};

/**
 * @description Check if any action is being currently dispatched.
 * @returns {boolean} True if dispatcher is in the dispatching process
 */
export const isDispatching = function(): boolean {
  return isCurrentlyDispatching;
};
