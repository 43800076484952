interface StorageItem {
  key: string;
  value: string;
}

const storage: StorageItem[] = [];

const inMemoryStorage = {
  clear(): void {
    storage.length = 0;
  },

  getItem(key: string): string | null {
    for (const item of storage) {
      if (item.key === key) {
        return item.value;
      }
    }

    return null;
  },

  key(index: number): string | null {
    if (!storage[index]) {
      return null;
    }

    return storage[index].key;
  },

  removeItem(key: string): void {
    let index = -1;

    for (let i = 0; i < storage.length; i++) {
      if (storage[i].key === key) {
        index = i;
        break;
      }
    }

    if (index > -1) {
      storage.splice(index, 1);
    }
  },

  setItem(key: string, value: any): void {
    const [matchingItem] = storage.filter(keyValuePair => keyValuePair.key === key);

    if (matchingItem) {
      matchingItem.value = String(value);
    } else {
      storage.push({ key, value: String(value) });
    }
  }
} as Storage;

Object.defineProperty(inMemoryStorage, 'length', {
  configurable: false,
  enumerable: true,
  get(): number {
    return storage.length;
  }
});

export default inMemoryStorage;
