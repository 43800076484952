import dateFormatter from './dateFormatter';
import * as util from './util';
import { getCurrentLocale } from './i18nService';
import { getSettings } from './settingsService';
import { NumberPatterns } from '../locales/locale';

const DECIMAL_SEP = '.';

const formatNumber = function(
  inputNumber: number,
  pattern: NumberPatterns,
  groupSep: string,
  decimalSep: string,
  fractionSize: number,
  symbolPosition?: string
): string {
  if (typeof inputNumber === 'object') {
    return '';
  }

  let isNegative = inputNumber < 0;
  inputNumber = Math.abs(inputNumber);
  const isInfinity = inputNumber === Infinity;

  if (!isInfinity && !isFinite(inputNumber)) {
    return '';
  }

  const numStr = String(inputNumber);
  let formattedText = '';
  let hasExponent = false;
  const parts = [];

  if (isInfinity) {
    formattedText = '\u221e';
  }

  if (!isInfinity && numStr.indexOf('e') !== -1) {
    const numberWithExponentRegExp = /([\d.]+)e(-?)(\d+)/;
    const match = numStr.match(numberWithExponentRegExp);

    if (match && match[2] === '-' && +match[3] > fractionSize + 1) {
      inputNumber = 0;
    } else {
      formattedText = numStr;
      hasExponent = true;
    }
  }

  if (!isInfinity && !hasExponent) {
    const fractionLen = (numStr.split(DECIMAL_SEP)[1] || '').length;

    // determine fractionSize if it is not specified
    if (typeof fractionSize === 'undefined') {
      fractionSize = Math.min(Math.max(pattern.minFrac, fractionLen), pattern.maxFrac);
    }

    // safely round numbers in JS without hitting imprecisions of floating-point arithmetics inspired by:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
    // eslint-disable-next-line prefer-template
    inputNumber = +(Math.round(+(inputNumber.toString() + 'e' + fractionSize)).toString() + 'e' + -fractionSize);

    const numberParts = String(inputNumber).split(DECIMAL_SEP);
    const [whole] = numberParts;
    let fraction = numberParts[1] || '';

    let i;
    let pos = 0;
    const lgroup = pattern.lgSize;
    const group = pattern.gSize;

    if (whole.length >= lgroup + group) {
      pos = whole.length - lgroup;
      for (i = 0; i < pos; i++) {
        if ((pos - i) % group === 0 && i !== 0) {
          formattedText += groupSep;
        }

        formattedText += whole.charAt(i);
      }
    }

    for (i = pos; i < whole.length; i++) {
      if ((whole.length - i) % lgroup === 0 && i !== 0) {
        formattedText += groupSep;
      }

      formattedText += whole.charAt(i);
    }

    // format fraction part
    while (fraction.length < fractionSize) {
      fraction += '0';
    }

    if (fractionSize > 0) {
      formattedText += decimalSep + fraction.substr(0, fractionSize);
    }
  } else if (fractionSize > 0 && inputNumber < 1) {
    formattedText = inputNumber.toFixed(fractionSize);
    inputNumber = parseFloat(formattedText);
  }

  if (inputNumber === 0) {
    isNegative = false;
  }

  const symbolPlaceholder = '\u00A4';
  parts.push(isNegative ? pattern.negPre : pattern.posPre);

  if (symbolPosition === 'prefix') {
    parts.push(symbolPlaceholder);
  }

  parts.push(formattedText, isNegative ? pattern.negSuf : pattern.posSuf);

  if (symbolPosition === 'suffix') {
    parts.push(symbolPlaceholder);
  }

  return parts.join('');
};

const formatCadastre = function(inputCadastre: any): string {
  const knrLength = 4;
  let result: string = null;

  if (inputCadastre.knr) {
    result = ''
      .concat(padLeft(inputCadastre.knr || 0, knrLength))
      .concat('/')
      .concat(inputCadastre.gnr || 0)
      .concat('/')
      .concat(inputCadastre.bnr || 0)
      .concat('/')
      .concat(inputCadastre.fnr || 0)
      .concat('/')
      .concat(inputCadastre.snr || 0);

    if (inputCadastre.nivaa) {
      result = result.concat(' ').concat(inputCadastre.nivaa);
    }
  } else if (inputCadastre.Knr) {
    result = ''
      .concat(padLeft(inputCadastre.Knr || 0, knrLength))
      .concat('/')
      .concat(inputCadastre.Gnr || 0)
      .concat('/')
      .concat(inputCadastre.Bnr || 0)
      .concat('/')
      .concat(inputCadastre.Fnr || 0)
      .concat('/')
      .concat(inputCadastre.Snr || 0);

    if (inputCadastre.nivaa) {
      result = result.concat(' ').concat(inputCadastre.nivaa);
    }
  } else if (inputCadastre.municipalityNumber) {
    result = ''
      .concat(padLeft(inputCadastre.municipalityNumber || 0, knrLength))
      .concat('/')
      .concat(inputCadastre.cadastralUnitNumber || 0)
      .concat('/')
      .concat(inputCadastre.unitNumber || 0)
      .concat('/')
      .concat(inputCadastre.leaseholdUnitNumber || 0)
      .concat('/')
      .concat(inputCadastre.sectionNumber || 0);
  }

  return result;
};

const padSource = function(
  source: string | number,
  minLength: number,
  paddingDirection: string,
  paddingChar: string = '0'
): string {
  if (util.isNumber(source)) {
    source = source.toString();
  }

  if (source.length >= minLength) {
    return source;
  }

  while (source.length < minLength) {
    source = paddingDirection === 'left' ? paddingChar + source : source + paddingChar;
  }

  return source;
};

export const date = function(input: string | number | Date, format?: string, timezone?: string): string {
  const locale = getCurrentLocale();
  const settings = getSettings();

  if (format == null && settings.dateFormat) {
    format = settings.dateFormat;
  }

  return dateFormatter(locale, input, format, timezone);
};

export const number = function(inputNumber: number, fractionSize: number): string {
  const locale = getCurrentLocale();
  const formats = locale.NUMBER_FORMATS;

  // if null or undefined pass it through
  return inputNumber == null
    ? String(inputNumber)
    : formatNumber(inputNumber, formats.PATTERNS[0], formats.GROUP_SEP, formats.DECIMAL_SEP, fractionSize);
};

export const currency = function(
  amount: number,
  currencySymbol?: string,
  symbolPosition?: string,
  fractionSize?: number
): string {
  const locale = getCurrentLocale();
  const settings = getSettings();
  const formats = locale.NUMBER_FORMATS;

  if (currencySymbol == null) {
    currencySymbol = settings.currency || formats.CURRENCY_SYM;
  }

  if (symbolPosition == null) {
    symbolPosition = settings.currencySymbolPosition || 'suffix';
  }

  if (fractionSize == null) {
    fractionSize = formats.PATTERNS[1].maxFrac;
  }

  // if null or undefined pass it through
  return amount == null
    ? ''
    : formatNumber(
        amount,
        formats.PATTERNS[1],
        formats.GROUP_SEP,
        formats.DECIMAL_SEP,
        fractionSize,
        symbolPosition
      ).replace(/\u00A4/g, currencySymbol);
};

/**
 * @description Return string with capitalized first letter and lowercased other letters
 * @param {string} text String to capitalize
 * @returns {string} Capitalized string
 */
export const capitalize = function(text: string): string {
  if (!text) {
    return text;
  }

  return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
};

/**
 * @description Converts a string to Start case
 * @param {string} text String to convert
 * @param {string} separator String used to separate words
 * @returns {string} Converted string
 */
export const toStartCase = function(text: string, separator: string = ' '): string {
  if (!text) {
    return text;
  }

  return text
    .split(separator)
    .map(capitalize)
    .join(separator);
};

export const cadastre = function(input: any): string {
  if (typeof input === 'object') {
    return formatCadastre(input);
  } else if (typeof input === 'string') {
    return formatCadastre(JSON.parse(input));
  }

  return input;
};

/**
 * @description Pad the input string on the left side to match the specified minimum length.
 * Returns the original string if the string's length is already equal or greater than the specified minimum length.
 * If a number is provided as the source, then it's converted to a string.
 * @param {string | number} source Source string or number
 * @param {number} minLength Required minimum length
 * @param {string} paddingChar Character that should be used for padding
 * @returns {string} Padded string
 */
export const padLeft = function(source: string | number, minLength: number, paddingChar?: string): string {
  return padSource(source, minLength, 'left', paddingChar);
};

/**
 * @description Pad the input string on the right side to match the specified minimum length.
 * Returns the original string if the string's length is already equal or greater than the specified minimum length.
 * If a number is provided as the source, then it's converted to a string.
 * @param {string | number} source Source string or number
 * @param {number} minLength Required minimum length
 * @param {string} paddingChar Character that should be used for padding
 * @returns {string} Padded string
 */
export const padRight = function(source: string | number, minLength: number, paddingChar?: string): string {
  return padSource(source, minLength, 'right', paddingChar);
};

/**
 * @description Format date from EDR (1928-06-16) to dd.mm.yyyy
 * @param {string} text Date string in format yyyy-mm-dd
 * @returns {string} Date string in format dd.mm.yyyy
 */
export const edrDate = function(text: string): string {
  if (!text) {
    return text;
  }

  let result = '';
  const dateArr = text.split('-');
  const [year, month, day] = dateArr;

  if (dateArr.length > 0) {
    if (year !== '0001') {
      result = `${day}.${month}.${year}`;
    } else {
      result = '';
    }
  } else {
    result = 'ERR';
  }

  return result;
};

// TODO move out of core, filter is using very specific data structures not known to core
/**
 * @description Writes out a fraction
 * @param fraction
 * @returns {string}
 */
export const edrFraction = function(fraction: any): string {
  if (!fraction.teller && !fraction.nevner) {
    return 'Finner ikke br�k';
  }

  return `${fraction.teller} / ${fraction.nevner}`;
};

/**
 * @description Format time from EDR (111500) to hh:mm
 * @param text EDR time string
 * @returns {string} Formatted time string
 */
export const edrTime = function(text: string): string {
  if (!text) {
    return text;
  }

  let result = '';

  if (text.length === 6) {
    result = `${text.substring(0, 2)}:${text.substring(2, 4)}`;
  } else if (text.length === 5) {
    text = `0${text}`;
    result = `${text.substring(0, 2)}:${text.substring(2, 4)}`;
  } else {
    result = 'ERR';
  }

  return result;
};

// TODO move out of core, filter is using very specific data structures not known to core
/**
 * @description Writes out a document
 * @param document
 * @returns {string}
 */
export const edrDocument = function(document: any): string {
  if (!document.nummer && !document.aar) {
    return 'Finner ikke document';
  }

  const name = document.navn ? document.navn.content : '';

  return `${document.nummer} / ${document.aar} ${name}`;
};

/**
 * @description Neutralize social security number in query
 * @param queryString
 * @returns {string}
 */
export const obfuscateSocialSecurityNumbers = function(queryString: string): string {
  const socialSecurityNumberRegex = /(^|[^\d])(\d{6}[^\d]?)(\d+)(?=[^\d]|$)/g;
  let match;
  let cursor = 0;
  let replacedQuery = '';
  match = socialSecurityNumberRegex.exec(queryString);

  while (match !== null) {
    const [, matchPrefix, birthDate, personalNumber] = match;

    const obfuscationString = new Array(personalNumber.length + 1).join('X');

    replacedQuery += queryString.substring(cursor, match.index);
    replacedQuery += matchPrefix + birthDate + obfuscationString;

    cursor = match.index + birthDate.length + matchPrefix.length + personalNumber.length;
    match = socialSecurityNumberRegex.exec(queryString);
  }

  replacedQuery += queryString.substring(cursor);

  return replacedQuery;
};

export const chunkString = function(input: string, chunkLength: number, delimiter: string = ' '): string {
  if (!input || !chunkLength) {
    return input;
  }

  const regex = new RegExp(`.{1,${chunkLength}}`, 'g');
  const chunks = input.match(regex);

  return chunks.join(delimiter);
};
