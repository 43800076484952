import * as broadcastService from '../services/broadcastService';
import * as broadcastConstants from '../constants/broadcast';
import { removeGeoToken, isValidToken, remove as removeToken } from '../dataServices/token';
import { dispatch } from '../dispatcher/dispatcher';
import {
  TOKEN_CHANGE_ACTION,
  LOGIN_FAILED_ACTION,
  LOGIN_SUCCESS_ACTION,
  UPDATE_USER_ACTION,
  LOGOUT_ACTION,
  TOKEN_NOT_FOUND_ACTION
} from '../constants/core';
import Promise from '../promise';
import * as logService from '../services/logService';
import { User } from '../typings/User';
import {
  AGREEMENT_CUSTOMER,
  AGREEMENT_CASH_CUSTOMER,
  ANONYMOUS_CUSTOMER,
  AGREEMENT_CUSTOMER_CARD_PAYMENT
} from '../constants/user';
import coreStore from '../stores/coreStore';
import { assign, find, shallowEqual } from '../services/util';
import { Common } from '../typings/Common';
import { getUserData } from '../dataServices/user';
import { XHRError } from '../services/errorService';

const triggerLoginFailed = function(): void {
  dispatch({
    actionType: LOGIN_FAILED_ACTION
  });
};

const triggerLoginSuccess = function(): void {
  dispatch({
    actionType: LOGIN_SUCCESS_ACTION
  });
};

const triggerTokenChanged = function(): void {
  dispatch({
    actionType: TOKEN_CHANGE_ACTION
  });
};

const getParameterValue = function(userData: User.ApiUser, matchName: string): string {
  const parameter: Common.Parameter = find(userData.parameters, ({ name }: Common.Parameter) => name === matchName);

  return parameter ? parameter.value : undefined;
};

const hasRole = function(authorizations: Common.Authorization[], role: string): boolean {
  return authorizations.some(({ code }: Common.Authorization): boolean => code === role);
};

const setParameters = function(userData: User.ApiUser): User.User {
  const canChangePassword = getParameterValue(userData, 'canChangePassword') !== 'false';

  return assign(userData, {
    canChangePassword
  });
};

const setRoles = function(userData: User.ApiUser): User.User {
  const isAnonymousCustomer = userData.organization && userData.organization.organizationType === ANONYMOUS_CUSTOMER;
  const isCreditCardCustomer =
    (userData.organization && userData.organization.organizationType === AGREEMENT_CASH_CUSTOMER) ||
    userData.organization.organizationType === AGREEMENT_CUSTOMER_CARD_PAYMENT;
  const isSubscriptionCustomer = userData.organization && userData.organization.organizationType === AGREEMENT_CUSTOMER;

  const { authorizations } = userData;

  const isCustomer = hasRole(authorizations, 'NEAGILUSERS');
  const isCustomerAdmin = hasRole(authorizations, 'NEAGADMINUSER');
  const isNationalIdentificationNumberEnabled = hasRole(authorizations, 'NETILGFNR');
  const isNewBasis = hasRole(authorizations, 'NEAGNEWBASIS');
  const isNewEksport = hasRole(authorizations, 'NEAGNEWEKSPORT');
  const isNewInfolandExclusive = hasRole(authorizations, 'NEIL2015');
  const isRegistryReportCustomer = hasRole(authorizations, 'EDRLOOKUPREPORT');
  const isSuperAdmin = hasRole(authorizations, 'NEAGNEADMINUSER');
  const isSupplier = hasRole(authorizations, 'NEAGILSUPPLIERS');
  const isUserAbleToShop = hasRole(authorizations, 'NEAGILUHAND');
  const isUserAdmin = hasRole(authorizations, 'NEAGADMGROUP');

  return assign(userData, {
    isAnonymousCustomer,
    isCreditCardCustomer,
    isCustomer,
    isCustomerAdmin,
    isNationalIdentificationNumberEnabled,
    isNewBasis,
    isNewEksport,
    isNewInfolandExclusive,
    isRegistryReportCustomer,
    isSubscriptionCustomer,
    isSuperAdmin,
    isSupplier,
    isUserAbleToShop,
    isUserAdmin
  });
};

const updateUser = function(userData: User.ApiUser): void {
  if (userData) {
    userData = setParameters(userData);
    userData = setRoles(userData);
  }

  const currentUser = coreStore.getUser();

  if (!shallowEqual(currentUser, userData)) {
    dispatch({
      actionType: UPDATE_USER_ACTION,
      userData
    });
    triggerLoginSuccess();
  }
};

const loginErrorHandling = function(error: XHRError): void {
  const HTTP_STATUS_UNAUTHORISED = 401;

  if (error.status && error.status === HTTP_STATUS_UNAUTHORISED) {
    removeToken();
  } else {
    removeUser();
  }

  triggerLoginFailed();
  logService.error(error.message, error);
};

let fetchUserPromise: Promise<any>;

const fetchAndUpdateUser = function(): Promise<void> {
  if (fetchUserPromise) {
    fetchUserPromise.cancel();
  }

  fetchUserPromise = getUserData()
    .then(updateUser)
    .catch(loginErrorHandling);

  return fetchUserPromise;
};

const removeUser = function(): void {
  dispatch({
    actionType: LOGOUT_ACTION
  });
};

const triggerNoTokenFound = function(): void {
  dispatch({
    actionType: TOKEN_NOT_FOUND_ACTION
  });
};

export const initTokenHandling = function(): void {
  broadcastService.subscribe(
    (message: string): void => {
      switch (message) {
        case broadcastConstants.TOKEN_REMOVED:
          removeGeoToken();
          triggerTokenChanged();
          removeUser();
          break;
        case broadcastConstants.TOKEN_REPLACED:
          triggerTokenChanged();

          if (isValidToken()) {
            fetchAndUpdateUser();
          }

          break;
        case broadcastConstants.ENVIRONMENT_SET:
          if (isValidToken()) {
            fetchAndUpdateUser();
          } else {
            triggerNoTokenFound();
          }

          break;
        default:
          break;
      }
    }
  );
};
