import { Locale } from './locale';

const locale: Locale = {
  DATETIME_FORMATS: {
    AMPMS: ['AM', 'PM'],
    DAY: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    ERANAMES: ['Before Christ', 'Anno Domini'],
    ERAS: ['BC', 'AD'],
    FIRSTDAYOFWEEK: 6,
    MONTH: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    SHORTDAY: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    SHORTMONTH: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    WEEKENDRANGE: [5, 6],
    fullDate: 'EEEE, MMMM d, y',
    longDate: 'MMMM d, y',
    medium: 'MMM d, y h:mm:ss a',
    mediumDate: 'MMM d, y',
    mediumTime: 'h:mm:ss a',
    short: 'M/d/yy h:mm a',
    shortDate: 'M/d/yy',
    shortTime: 'h:mm a'
  },
  NUMBER_FORMATS: {
    CURRENCY_SYM: '$',
    DECIMAL_SEP: '.',
    GROUP_SEP: ',',
    PATTERNS: [
      {
        gSize: 3,
        lgSize: 3,
        maxFrac: 3,
        minFrac: 0,
        minInt: 1,
        negPre: '-',
        negSuf: '',
        posPre: '',
        posSuf: ''
      },
      {
        gSize: 3,
        lgSize: 3,
        maxFrac: 2,
        minFrac: 2,
        minInt: 1,
        negPre: '-',
        negSuf: '',
        posPre: '',
        posSuf: ''
      }
    ]
  },
  id: 'en-us'
};

export default locale;
