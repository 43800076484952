import { TYPE_FEATURE, TYPE_FEATURE_COLLECTION } from '../constants/geoJSON';
import { Common } from '../typings/Common';

/**
 * @description Copies own properties from source objects into target object. If `deep` parameter is set to true
 * recursively copies nested objects.
 * @param dst Target object
 * @param objs Array with source objects
 * @param deep If `true` deep object copy is performed
 * @returns Reference to target object
 */
const baseExtend = function(dst: any, objs: any[], deep?: boolean): any {
  const ii = objs.length;
  for (let i = 0; i < ii; ++i) {
    const obj = objs[i];

    if (!isObject(obj) && !isFunction(obj)) {
      continue;
    }

    const keys = Object.keys(obj);
    const jj = keys.length;
    for (let j = 0; j < jj; j++) {
      const key = keys[j];
      const src = obj[key];

      if (deep && isObject(src)) {
        if (isDate(src)) {
          dst[key] = new Date(src.valueOf());
        } else if (isRegExp(src)) {
          dst[key] = new RegExp(src);
        } else {
          if (!isObject(dst[key])) {
            dst[key] = isArray(src) ? [] : {};
          }

          baseExtend(dst[key], [src], true);
        }
      } else {
        dst[key] = src;
      }
    }
  }

  return dst;
};

/**
 * @description Determines if a reference is a `String`.
 * @param {any} value Reference to check.
 * @returns {boolean} True if `value` is a `String`.
 */
export const isString = function(value: any): value is string {
  return typeof value === 'string';
};

/**
 * @description Determines if a reference is a `Number`. *
 * This includes the "special" numbers `NaN`, `+Infinity` and `-Infinity`.
 *
 * If you wish to exclude these then you can use the native `isFinite` method
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/isFinite}
 *
 * @param {*} value Reference to check.
 * @returns {boolean} True if `value` is a `Number`.
 */
export const isNumber = function(value: any): value is number {
  return typeof value === 'number';
};

/**
 * @description Determines if a reference is an `Object`. Unlike `typeof` in JavaScript, `null`s are not
 * considered to be objects. Note that JavaScript arrays are objects.
 *
 * @param {*} value Reference to check.
 * @returns {boolean} True if `value` is an `Object` but not `null`.
 */
export const isObject = function(value: any): boolean {
  return value !== null && typeof value === 'object';
};

/**
 * @description Determines if a reference is a `Function`.
 * @param {*} value Reference to check
 * @returns {boolean} True if `value` is a `Function`
 */
export const isFunction = function(value: any): value is Function {
  return typeof value === 'function';
};

/**
 * @description Determines if a value is an `Array`
 * @param {*} value Value to check
 * @returns {boolean} True if `value` is an `Array`
 */
export const isArray = function(value: any): value is any[] {
  return Object.prototype.toString.call(value) === '[object Array]';
};

/**
 * @description Determines if a value is a date.
 *
 * @param {*} value Reference to check.
 * @returns {boolean} True if `value` is a `Date`.
 */
export const isDate = function(value: any): value is Date {
  return Object.prototype.toString.call(value) === '[object Date]';
};

/**
 * @description Determines if a value is a regular expression object.
 *
 * @param {*} value Reference to check.
 * @returns {boolean} True if `value` is a `RegExp`.
 */
export const isRegExp = function(value: any): value is RegExp {
  return Object.prototype.toString.call(value) === '[object RegExp]';
};

/**
 * @description Determines if a reference is undefined.
 * @param {*} value Reference to check.
 * @returns {boolean} True if `value` is undefined.
 */
export const isUndefined = function(value: any): boolean {
  return typeof value === 'undefined';
};

/**
 * @description Determines if a reference is defined.
 * @param {*} value Reference to check.
 * @returns {boolean} True if `value` is defined.
 */
export const isDefined = function(value: any): boolean {
  return typeof value !== 'undefined';
};

/**
 * @description Determines if a value is a boolean (true or false).
 * @param {*} value Value to check.
 * @returns {boolean} True if `value` is either true or false.
 */
export const isBoolean = function(value: any): boolean {
  return typeof value === 'boolean';
};

/**
 * @description Converts the specified string to uppercase.
 * @param {string} str String to be converted to uppercase.
 * @returns {string} Uppercased string.
 */
export const uppercase = function(str: string): string {
  return isString(str) ? str.toUpperCase() : str;
};

/**
 * @description Converts the specified string to lowercase.
 * @param {string} str String to be converted to lowercase.
 * @returns {string} Lowercased string.
 */
export const lowercase = function(str: string): string {
  return isString(str) ? str.toLowerCase() : str;
};

/**
 * @description Converts string into integer number
 * @param {string} value String to be converted into number
 * @returns {number} Resulting number
 */
export const toInt = function(value: string): number {
  return parseInt(value, 10);
};

/* tslint:disable max-line-length */
const R_ISO8601_STR = /^(\d{4})-?(\d\d)-?(\d\d)(?:T(\d\d)(?::?(\d\d)(?::?(\d\d)(?:\.(\d+))?)?)?(Z|([+-])(\d\d):?(\d\d))?)?$/;
// ___________________1________2_______3_________4__________5__________6__________7__________8__9_____10______11
/* tslint:enable max-line-length */
/**
 * @description Converts JSON date string into `Date`
 * or returns original string if it doesn't match ISO 8601 date standard
 * @param {string} str JSON date string
 * @returns {string | Date} `Date` or original string
 */
export const jsonStringToDate = function(str: string): string | Date {
  const match = str.match(R_ISO8601_STR);

  if (match) {
    const date = new Date(0);
    let tzHour = 0;
    let tzMin = 0;
    const dateSetter = match[8] ? date.setUTCFullYear : date.setFullYear;
    const timeSetter = match[8] ? date.setUTCHours : date.setHours;

    if (match[9]) {
      tzHour = toInt(match[9] + match[10]);
      tzMin = toInt(match[9] + match[11]);
    }

    dateSetter.call(date, toInt(match[1]), toInt(match[2]) - 1, toInt(match[3]));
    const h = toInt(match[4] || '0') - tzHour;
    const m = toInt(match[5] || '0') - tzMin;
    const s = toInt(match[6] || '0');
    const ms = Math.round(parseFloat(`0.${match[7] || 0}`) * 1000);
    timeSetter.call(date, h, m, s, ms);

    return date;
  }

  return str;
};

/**
 * @description Determines if value is empty.
 * @param {*} value Value to check.
 * @returns {boolean} True if `value` is empty.
 */
export const isEmpty = function(value: any): boolean {
  const isNull = value === null;
  const isEmptyString = typeof value === 'string' && value.trim().length === 0;

  return isNull || isUndefined(value) || isEmptyString;
};

/**
 * @description Encodes object's key-value pairs into uri query string
 * @param {object} data object to be encoded
 * @returns {string} Resulting URI query string
 */
export const encodeUriQuery = function(data: object): string {
  if (!data) {
    return '';
  }

  const encodedData = [];

  Object.keys(data).forEach((key: string) => {
    if (isEmpty(data[key])) {
      return;
    }

    if (Array.isArray(data[key])) {
      return data[key].forEach((item: string) => {
        if (isEmpty(item)) {
          return;
        }

        return encodedData.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
      });
    }

    return encodedData.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
  });

  return encodedData.join('&');
};

/**
 * @description Decodes uri query into object containing decoded key-value pairs
 * @param {string} data Encoded uri query string
 * @returns {object} Object containing decoded key-value pairs
 */
export const decodeUriQuery = function(data: string): object {
  const decodedData = {};

  data.split('&').forEach(dataItem => {
    const pair = dataItem.split('=');
    const key = decodeURIComponent(pair[0]);

    if (key === '' || isUndefined(pair[1])) {
      return;
    }

    const value = decodeURIComponent(pair[1]);

    if (decodedData.hasOwnProperty(key)) {
      isArray(decodedData[key]) ? decodedData[key].push(value) : (decodedData[key] = [decodedData[key], value]);
    } else {
      decodedData[key] = value;
    }
  });

  return decodedData;
};

/**
 * @descriptions Add parameters to url
 * @param {string} url Url string
 * @param {object} params Parameter object
 * @returns {any}
 */
export const addUrlParams = function(url: string, params: object): string {
  const [baseUrl, queryParams] = url.split('?');

  if (queryParams) {
    const decodedParams = decodeUriQuery(queryParams);
    const mergedParams = assign(decodedParams, params);

    return `${baseUrl}?${encodeUriQuery(mergedParams)}`;
  }

  return `${baseUrl}?${encodeUriQuery(params)}`;
};

/**
 * @description Return a copy of the `source` object, filtered to only have values for the whitelisted `keys`
 * @param {object} source Source object
 * @param {Array<string>} keys Whitelisted keys
 * @returns {*} Object containing only whitelisted properties from source object
 */
export const pick = function(source: object, keys: string[]): any {
  const result = {};
  const ii = keys.length;
  for (let i = 0; i < ii; i++) {
    if (keys[i] in source) {
      result[keys[i]] = source[keys[i]];
    }
  }

  return result;
};

/**
 * @description Return a copy of the `source` object, filtered to remove values for the blacklisted `keys`
 * @param {object} source Source object
 * @param {Array<string>} keys Blacklisted keys
 * @returns {*} Object containing only non-blacklisted properties from source object
 */
export const copyExclude = function(source: object, keys: string[]): any {
  const result = {};
  const sourceKeys: string[] = Object.keys(source);
  const ii = sourceKeys.length;
  for (let i = 0; i < ii; i++) {
    if (keys.indexOf(sourceKeys[i]) < 0) {
      result[sourceKeys[i]] = source[sourceKeys[i]];
    }
  }

  return result;
};

/**
 * @description Return a copy of the `source` object, filtered to only have values for the whitelisted `keys`
 * @param {object} source Source object
 * @param {Array<OriginalAndTransformed>} keys Whitelisted. Original=original name in source, transformed is new name.
 * @returns {*} Object containing only whitelisted properties from source object
 */
export const pickAndTransform = function(source: object, keys: Common.OriginalAndTransformed[]): any {
  const result = {};
  const ii = keys.length;
  for (let i = 0; i < ii; i++) {
    if (keys[i].original in source) {
      result[keys[i].transformed] = source[keys[i].original];
    }
  }

  return result;
};

/**
 * @description Return array containing key/value pairs for source object's own enumerable properties
 * @param source Source object
 * @returns {any[]} Array with key/value pairs
 */
export const pairs = function(source: object): any[] {
  if (!isObject(source)) {
    return null;
  }

  const keys = Object.keys(source).sort();

  return keys.map(key => ({ key, value: source[key] }));
};

/**
 * @description Deeply extends the destination object `target` by copying own enumerable properties
 * from the `source` object(s) to `target`. You can specify multiple `source` objects.
 * If you want to preserve original objects, you can do so
 * by passing an empty object as the target: `var object = util.merge({}, object1, object2)`.
 * Function recursively descends into object properties of source.
 * objects, performing a deep copy.
 * @param {object} target Merge target
 * @param source Source objects
 * @returns {object} Reference to target object
 */
export const merge = function(target: object, ...source: object[]): object {
  return baseExtend(target, source, true);
};

/**
 * @description Returns deep copy of a `source` object
 * @param source Source object for cloning
 * @returns {*} Source object's clone
 */
export const clone = function(source: any): any {
  if (!isObject(source)) {
    return source;
  }

  return baseExtend({}, [source], true);
};

/**
 * @description Returns deep copy of a provided array
 * @param {any[]} source Original array
 * @returns {any[]} Clone of the original array
 */
export const cloneArray = function(source: any[]): any[] {
  return baseExtend([], [source], true);
};

/**
 * @description Copies own, enumerable properties from source object(s) into target object
 * @param {any} target Target object
 * @param sources Source objects
 * @returns {any} Target object
 */
export const assign = function(target: any, ...sources: any[]): any {
  return baseExtend(target, sources);
};

/**
 * @description Maps array elements using supplied callback function
 * @param array
 * @param callback
 * @returns {any[]} Mapped array
 */
export const map = function(array: any[], callback: (value: any, index?: number, array?: any[]) => any): any[] {
  return array.map(callback);
};

/**
 * @description Check if collection contains specified value
 * @param array Collection to check
 * @param value Value to check
 * @returns {boolean} True if collection contains value
 */
export const contains = function(array: any[], value: any): boolean {
  if (!array) {
    return false;
  }

  return array.indexOf(value) !== -1;
};

/**
 * @description Check if collection contains specified object
 * @param array Collection to check
 * @param object Object to check
 * @returns {boolean} True if collection contains object
 */
export const containsObject = function(array: any[], object: object): boolean {
  if (!array) {
    return false;
  }

  return array.some(element => shallowEqual(element, object));
};

/**
 * @description Sort an array of objects by specified property
 * @param {any[]} array Array of objects
 * @param {string} property Property name
 * @returns {any[]} Sorted array
 */
export const sortBy = function(array: any[], property: string): any[] {
  return array.sort((a, b) => {
    if (a[property] < b[property]) {
      return -1;
    }

    if (a[property] > b[property]) {
      return 1;
    }

    return 0;
  });
};

/**
 * @description Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for N milliseconds.
 * @param {Function} func Function that should be debounced
 * @param {number} wait Debouncing time period in milliseconds
 * @returns {Function} Debounced function
 */
export const debounce = function(func: Function, wait: number): Function {
  let timeout;

  return function(...args: any[]): void {
    const context = this;
    const later = function(): void {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

/**
 * Check if 2 objects are equal by comparing own properties of objects. Nested objects are compared by reference
 * @param obj
 * @param obj1
 * @returns {boolean} True if objects are equal
 */
export const shallowEqual = function(obj: object, obj1: object): boolean {
  if (obj === obj1) {
    return true;
  }

  if (JSON.stringify(obj) === JSON.stringify(obj1)) {
    return true;
  }

  if (!isObject(obj) || !isObject(obj1)) {
    return false;
  }

  const keys = Object.keys(obj);
  const keys1 = Object.keys(obj1);

  if (keys.length !== keys1.length) {
    return false;
  }

  let i = keys.length;
  while (i--) {
    if (obj[keys[i]] !== obj1[keys[i]]) {
      return false;
    }
  }

  return true;
};

/**
 * @description Checks if geojson object is valid. Does very basic check based on provided object's type.
 * @param geojson Reference to check for validity
 * @returns {boolean} True if provided reference is valid geojson object
 */
export const isGeoDataValid = function(geojson: any): boolean {
  if (!isObject(geojson) || !geojson.type) {
    return false;
  }

  if (geojson.type === TYPE_FEATURE && !geojson.geometry) {
    return false;
  }

  if (geojson.type === TYPE_FEATURE_COLLECTION) {
    if (!geojson.features || geojson.features.length === 0) {
      return false;
    }
  }

  return true;
};

/**
 * @description Calls a predicate on each entry in the provided array and returns the item if
 * the predicate returns True.
 * @param {Array} array The array to call the predicate on
 * @param {Function} predicate Function that checks if element is matching search parameters
 * @returns {*} The array entry that returns True when provided to the predicate
 */
export const find = function<T = any>(array: T[], predicate: (value: T, index: number, arr: T[]) => boolean): T {
  for (let i = 0; i < array.length; i++) {
    const currentValue = array[i];

    if (predicate.call(predicate, currentValue, i, array)) {
      return currentValue;
    }
  }
};

const CONTROL_NUMBER_1_WEIGHT: number[] = [3, 7, 6, 1, 8, 9, 4, 5, 2, 1, 0];
const CONTROL_NUMBER_2_WEIGHT: number[] = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2, 1];

const modulus11Control = function(value: string, weight: number[]): boolean {
  let sumControl: any = 0;
  const { length } = value;
  const start = 11 - length;

  for (let i = 0; i < length; i++) {
    const currentNumber: any = Number(value.substring(i, i + 1));
    const weightNumber: any = weight[i + start];
    sumControl += weightNumber * currentNumber;
  }

  return sumControl % 11 === 0;
};

const organizationNumberLength = 9;

/**
 * Validates a numberstring as a norwegian organization number
 * @param {String} numberToValidate the string number to validate
 * @returns {Boolean} Result of validation
 */
export const isValidOrganizationNumber = function(numberToValidate: string): boolean {
  return (
    numberToValidate.length === organizationNumberLength && modulus11Control(numberToValidate, CONTROL_NUMBER_2_WEIGHT)
  );
};

const idNumberLength = 11;

/**
 * Validates a numberstring as a norwegian personal identification number
 * @param {String} numberToValidate the string number to validate
 * @returns {Boolean} Result of validation
 */
export const isValidIdNumber = function(numberToValidate: string): boolean {
  return (
    numberToValidate.length === idNumberLength &&
    modulus11Control(numberToValidate, CONTROL_NUMBER_1_WEIGHT) &&
    modulus11Control(numberToValidate, CONTROL_NUMBER_2_WEIGHT)
  );
};

/**
 * @description Returns a string that is safe for XML
 * @param {string} unsafe The string that will be escaped
 * @returns {string} Escaped string, safe for XML
 */
export const htmlEscapeString = function(unsafe: string): string {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#x27;')
    .replace(/\//g, '&#x2F;');
};

/**
 * @description Returns a object where strings are XML escaped
 * @param {object} o The object that will be escaped
 * @returns {object} Escaped JSON, safe for XML
 */
export const htmlEscapeObject = function(o: any): any {
  for (const i in o) {
    if (typeof o[i] === 'string') {
      o[i] = htmlEscapeString(o[i]);
    } else if (o[i] !== null && typeof o[i] === 'object') {
      // Going one step down in the object tree!!
      htmlEscapeObject(o[i]);
    }
  }
};

/**
 * @description Returns a delimited string with the fields of the provided object
 * Default to dash delimited
 * @param {object} objectToTransform Object to transform
 * @param {string} delimiter Delimiter to be used, defaults to dash delimiter
 * @returns {string} delimited string with the input object fields
 */
export const objectToDelimitedString = function(objectToTransform: object, delimiter: string = '-'): string {
  let retString = '';

  Object.keys(objectToTransform).forEach(key => {
    if (retString) {
      retString += objectToTransform[key] + delimiter;
    } else {
      retString = objectToTransform[key] + delimiter;
    }
  });

  if (retString) {
    return retString.substr(0, retString.length - 1);
  }
};

/**
 * @description Takes an input string and returns a potentially modified string where all occurrences
 * of the specified substring are replaced with a replacement string
 * @param {string} inputString the original input string
 * @param {string} stringToReplace the string in the input you want to replace
 * @param {string} stringToReplaceWith the string to replace all found occurences with
 * @returns {string} string with all occurrences of specified substring replaced with a specified replacement string
 */
export const stringReplaceAll = function(
  inputString: string,
  stringToReplace: string,
  stringToReplaceWith: string
): string {
  // escape reserved characters that the regex would try to evaluate.
  function escapeRegExp(str: string): string {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  }

  return inputString.replace(new RegExp(escapeRegExp(stringToReplace), 'g'), stringToReplaceWith);
};

/**
 * @description This function converts an array of arrays to an array of values only
 * @param array: any[]
 * @returns {any[]}
 */
export const flattenArray = function(array: any[]): any[] {
  return array.reduce(
    (previousValue, currentValue) =>
      previousValue.concat(Array.isArray(currentValue) ? flattenArray(currentValue) : currentValue),
    []
  );
};

/**
 * @description This function flattens a nested object into a simple object
 * @param object: Map<any> | object
 * @returns {Map<string>}
 */
export const flattenObject = function(object: object): object {
  const separator = '.';

  const flatten = (obj: object, prefix: string, flattened: object) => {
    for (const key of Object.keys(obj)) {
      const val = obj[key];

      if (isObject(val)) {
        flatten(val, prefix + key + separator, flattened);
      } else {
        flattened[prefix + key] = val;
      }
    }
  };

  const result: object = {};
  flatten(object, '', result);

  return result;
};

/**
 * @description Takes an object and traverses through it, applying a function to each sub object
 * @param {Object} object The object you want traversed
 * @param {Function} func The function to apply to each sub object. Function (parent: Object, key: string, value: any)
 * @returns {Object} Return the traversed object. Maybe it is manipulated?
 */
export const traverseObject = function(
  object: object,
  func: (key: string, index: number, object: object) => void
): object {
  Object.keys(object).forEach((key: string, index: number) => {
    func.apply(this, [key, index, object]);

    if (isObject(object[key])) {
      traverseObject(object[key], func);
    }
  });

  return object;
};

/**
 * @description Returns values in `source` that are present in `filter`.
 * @param {any[]} source
 * @param {any[]} filter
 * @returns {any[]}
 * @example
 * ```
 * intersectArrays([1, 2, 3, 4, 5], [0, 2, 4, 6]);
 * > [2, 4]
 * ```
 */
export const intersectArrays = function(source: any[], filter: any[]): any[] {
  return source.filter(value => filter.indexOf(value) !== -1);
};
