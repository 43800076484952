import { getJSON } from './xhr';
import { getConfig } from './servicesConfig';
import Promise from '../promise';
import { CodeRegister } from '../typings/CodeRegister';

export const getCountyById = function(id: string): Promise<CodeRegister.County> {
  return getJSON(`${getConfig().COUNTY_INFO_ADDRESS}/${id}`);
};

export const getCountyList = function(): Promise<CodeRegister.ApiCountyListResponse> {
  return getJSON(getConfig().COUNTY_INFO_ADDRESS);
};

export const getMunicipalityById = function(id: string): Promise<CodeRegister.Municipality> {
  return getJSON(`${getConfig().MUNICIPALITY_INFO_ADDRESS}/${id}`);
};

export const getMunicipalityList = function(): Promise<CodeRegister.ApiMunicipalityListResponse> {
  return getJSON(getConfig().MUNICIPALITY_INFO_ADDRESS);
};

export const getPostalAddress = function(postalCode: string): Promise<CodeRegister.PostalAddress> {
  const url = `${getConfig().POSTNAME_REGISTER}/${postalCode}`;

  return getJSON(url);
};
