import * as util from './util';
import * as storageService from './storageService';

const MAX_TTL = 86400;

export interface UrlCacherEntryType {
  expire: Date;
  ttl: number;
  url: string;
}

export class UrlCacherEntry implements UrlCacherEntryType {
  expire: Date;

  ttl: number;

  url: string;

  constructor(url: string, ttl: number, expire?: Date) {
    this.url = url;
    this.ttl = ttl < MAX_TTL ? ttl : MAX_TTL;
    this.expire = expire ? expire : this.generateExpireTime();
  }

  generateExpireTime(): Date {
    const expireDate = new Date();
    expireDate.setSeconds(expireDate.getSeconds() + this.ttl);

    return expireDate;
  }

  hasExpired(): boolean {
    return this.expire < new Date();
  }

  hash(): string {
    let hash = 0;

    if (this.url.length === 0) {
      return hash.toString();
    }

    for (let i = 0; i < this.url.length; i++) {
      const char = this.url.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }

    return hash.toString().replace(/-/g, '');
  }
}

export class UrlCacherStorage {
  prefix = 'url-cacher-';

  get(cacheEntry: UrlCacherEntry): UrlCacherEntry {
    try {
      const cacheItem: UrlCacherEntryType = storageService.retrieve(this.prefix + cacheEntry.hash(), true);

      return cacheItem ? new UrlCacherEntry(cacheItem.url, cacheItem.ttl, cacheItem.expire) : null;
    } catch (err) {
      return null;
    }
  }

  set(cacheEntry: UrlCacherEntry): void {
    try {
      storageService.save(this.prefix + cacheEntry.hash(), cacheEntry);
    } catch (err) {
      return undefined;
    }
  }
}

export class UrlCacher {
  cacheEntry: UrlCacherEntry;

  storage: UrlCacherStorage;

  constructor(url: string, ttl: number) {
    this.cacheEntry = new UrlCacherEntry(url, ttl);
    this.storage = new UrlCacherStorage();
  }

  generate(cacheEntry: UrlCacherEntry): string {
    const [url, queryString] = cacheEntry.url.split('?');
    let queryParams: any = {
      cb: cacheEntry.expire.getTime()
    };

    if (queryString) {
      const existingQueryParams = util.decodeUriQuery(queryString);
      queryParams = util.merge(existingQueryParams, queryParams);
    }

    return util.addUrlParams(url, queryParams);
  }

  getCachedUrl(): string {
    let cacheEntry = this.storage.get(this.cacheEntry);

    if (!cacheEntry || cacheEntry.hasExpired()) {
      this.storage.set(this.cacheEntry);
      ({ cacheEntry } = this);
    }

    return this.generate(cacheEntry);
  }
}
