export const person = {
  dsfStates: {
    activeWithDNumber: {
      code: 'K',
      text: 'AKTIVT PERSON MED D-NR'
    },
    birthRegistered: {
      codeh: 'R',
      text: 'FØDSELSREGISTRERT'
    },
    cancelled: {
      code: 'A',
      text: 'ANNULLERT'
    },
    dead: {
      code: 'D',
      text: 'DØD'
    },
    disappeared: {
      code: 'F',
      text: 'FORSVUNNET'
    },
    disappearedWithDNumber: {
      code: 'S',
      text: 'SAVNET PERSON MED D-NR'
    },
    emigrated: {
      code: 'U',
      text: 'UTVANDRET'
    },
    noResidence: {
      code: 'I',
      text: 'IKKE BOSATT'
    },
    norwegianResidence: {
      code: 'B',
      text: 'BOSATT I NORGE'
    },
    undefined: {
      text: 'IKKE DEFINERT'
    },
    unknown: {
      code: 'X',
      text: 'UKJENT PERSON'
    }
  }
};

export enum UnitTypes {
  Unnumbered = 'U',
  Residence = 'B',
  Leisure = 'F',
  Other = 'A'
}
