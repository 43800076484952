import { CoreSettings } from '../typings/CoreSettings';

const DEFAULT_SETTINGS: CoreSettings = {
  language: 'nb',
  persistAccessTokenInStorage: true,
  persistGeoTokenInStorage: true
};

let settings: CoreSettings = { ...DEFAULT_SETTINGS };

export const setSettings = function(newSettings: CoreSettings): void {
  settings = { ...settings, ...newSettings };
};

export const getSettings = function(): CoreSettings {
  return settings;
};
