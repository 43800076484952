export default {
  BACKSPACE: 8,
  DELETE: 46,
  DOWN_ARROW: 40,
  ENTER: 13,
  ESC: 27,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  TAB: 9,
  UP_ARROW: 38
};
