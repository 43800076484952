import { register } from '../dispatcher/dispatcher';
import { EventEmitter } from 'eventemitter3';
import { isObject, isFunction } from '../services/util';

const STATE_CHANGED_EVENT = 'STATE_CHANGED_EVENT';

export interface StoreEvent {
  args?: any[];
  name: string;
}

export interface StoreAction {
  actionType: string;
}

export class Store<R> extends EventEmitter {
  dispatchToken: string;

  private state: R;

  constructor(
    stateReducer: (state: R, action: StoreAction) => R,
    eventCollector?: (state: R, action: StoreAction) => StoreEvent | StoreEvent[],
    initialState?: R
  ) {
    super();
    this.setState(initialState);
    this.dispatchToken = register(action => {
      const newState = stateReducer(this.state, action);

      if (newState !== this.state) {
        this.setState(newState);
        this.emit(STATE_CHANGED_EVENT, newState);
      }

      if (isFunction(eventCollector)) {
        const events = eventCollector(this.state, action);

        if (Array.isArray(events)) {
          events.forEach(event => this.emitEvent(event));
        } else if (isObject(events)) {
          this.emitEvent(events);
        }
      }
    });
  }

  getState(): R {
    return this.state;
  }

  subscribe(fn: (...args: any[]) => void): this {
    return this.on(STATE_CHANGED_EVENT, fn);
  }

  unsubscribe(fn: (...args: any[]) => void): this {
    return this.removeListener(STATE_CHANGED_EVENT, fn);
  }

  private emitEvent(event: StoreEvent): void {
    if (Array.isArray(event.args)) {
      this.emit.call(this, event.name, ...event.args);
    } else {
      this.emit.call(this, event.name);
    }
  }

  private setState(state: R): void {
    this.state = state || ({} as R);
  }
}
