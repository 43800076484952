import { contains, clone } from '../services/util';
import { RequestConfig } from '../typings/RequestConfig';
import * as Errors from '../services/errorService';
import * as servicesConfig from './servicesConfig';
import * as tokenService from './token';
import Promise from '../promise';

const HTTP_GET = 'GET';
const HTTP_POST = 'POST';
const HTTP_DELETE = 'DELETE';
const HTTP_PUT = 'PUT';

const HTTP_STATUS_OK = 200;
const HTTP_STATUS_CREATED = 201;
const HTTP_STATUS_NO_CONTENT = 204;
const HTTP_STATUS_NOT_MODIFIED = 304;
const HTTP_STATUS_FORBIDDEN = 403;
const SUCCESS_CODES = [HTTP_STATUS_OK, HTTP_STATUS_CREATED, HTTP_STATUS_NO_CONTENT, HTTP_STATUS_NOT_MODIFIED];

const IS_FORM_DATA_SUPPORTED = typeof FormData === 'function';

const cache = {};

const updateRequestForNonCORSBrowsers = function(
  xhr: XMLHttpRequest,
  method: string,
  url: string,
  authToken: string
): void {
  // for browsers without CORS support
  // send local request and rely on proxy existence
  let path = url;

  // if path is not relative drop domain part
  if (path.charAt(0) !== '/') {
    path = url.substr(url.indexOf('/', 'https://'.length));
  }

  const isTokenRequest = path.indexOf(servicesConfig.AUTHENTICATION_TOKEN_PATH) > -1;
  const isEDRRequest = path.indexOf(servicesConfig.EDR_ONLINE_ADDRESS) > -1;

  // EDR online and authentication endpoints cannot properly handle requests containing authorization parameter
  // TODO remove special case handling when there will be a way to handle token in the same way for all endpoints
  if (authToken && !isTokenRequest && !isEDRRequest) {
    // eslint-disable-next-line prefer-template
    path += (path.indexOf('?') !== -1 ? '&' : '?') + 'authorization=' + encodeURIComponent(authToken);
  }

  xhr.open(method, path, true);

  // EDR online and authentication endpoint require special token handling
  // TODO remove special case handling when there will be a way to handle token in the same way for all endpoints
  if (authToken && (isTokenRequest || isEDRRequest)) {
    xhr.setRequestHeader('Authorization', authToken);
  }
};

const ajax = function(
  method: string,
  url: string,
  config: RequestConfig.RequestConfig = {}
): Promise<RequestConfig.AjaxResponseWithHeader | any> {
  let { accessToken } = config;

  if (!config.noAuth && !accessToken) {
    const storedToken = tokenService.getAccessToken();

    if (storedToken instanceof Promise) {
      return Promise.resolve(storedToken).then(() => ajax(method, url, config));
    }

    accessToken = storedToken;
  }

  return new Promise<any>((resolve, reject, onCancel) => {
    if (!config.noAuth && accessToken === null) {
      reject(new Errors.XHRError('Not authenticated', HTTP_STATUS_FORBIDDEN));

      return;
    }

    if (config.cache && method === HTTP_GET && cache[url]) {
      resolve(cache[url]);

      return;
    }

    const xhr = new XMLHttpRequest();

    onCancel(() => xhr.abort());

    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
      xhr.withCredentials = config.withCredentials || false;

      if (accessToken) {
        xhr.setRequestHeader('Authorization', accessToken);
      }
    } else {
      updateRequestForNonCORSBrowsers(xhr, method, url, accessToken);
    }

    if (config.headers) {
      Object.keys(config.headers).forEach(key => xhr.setRequestHeader(key, config.headers[key]));
    }

    if (config.responseType) {
      xhr.responseType = config.responseType as XMLHttpRequestResponseType;
    }

    xhr.onload = function(): void {
      const requestResult = processRequestResult(xhr);

      if (requestResult instanceof Error) {
        reject(requestResult);
      } else {
        if (config.cache && method === HTTP_GET) {
          cache[url] = requestResult;
        }

        if (config.returnContentAndHeader) {
          resolve({
            body: requestResult,
            header: xhr.getAllResponseHeaders()
          });
        } else {
          resolve(requestResult);
        }
      }
    };

    xhr.onerror = function(): void {
      reject(new Errors.XHRError('XHR error occurred'));
    };

    if (method === HTTP_POST || method === HTTP_PUT || method === HTTP_DELETE) {
      if (typeof config.data === 'string') {
        xhr.send(config.data);
      } else if (IS_FORM_DATA_SUPPORTED && config.data instanceof FormData) {
        xhr.send(config.data);
      } else {
        if (!config.headers || (config.headers && !config.headers['Content-Type'])) {
          xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        }

        xhr.send(JSON.stringify(config.data));
      }
    } else {
      xhr.send();
    }
  });
};

const processRequestError = function(xhr: XMLHttpRequest): Errors.XHRError {
  let errorMessage = 'XHR request failed';
  let errorData;

  if (xhr.responseType === 'document' && xhr.responseXML) {
    errorData = xhr.responseXML;
  } else if (xhr.responseType !== 'blob' && xhr.responseText) {
    try {
      errorData = JSON.parse(xhr.responseText);
      errorMessage = errorData.message || errorMessage;
    } catch (exception) {
      errorData = xhr.responseText;
    }
  } else {
    errorData = 'No responseText.';
  }

  return new Errors.XHRError(errorMessage, xhr.status, xhr.statusText, errorData);
};

const processRequestResult = function(xhr: XMLHttpRequest): any {
  if (!contains(SUCCESS_CODES, xhr.status)) {
    return processRequestError(xhr);
  } else if (xhr.responseType === 'blob') {
    return xhr.response;
  } else if (xhr.responseType === 'text') {
    return xhr.responseText;
  } else if (xhr.responseType === 'document') {
    return xhr.responseXML;
  } else if (contains(SUCCESS_CODES, xhr.status) && !xhr.responseText && !xhr.response) {
    return {};
  }

  let data;

  try {
    data = JSON.parse(xhr.responseText);
  } catch (e) {
    return new Errors.XHRError(
      'XHR responseText could not be parsed as JSON',
      xhr.status,
      xhr.statusText,
      xhr.responseText as Errors.ApiError
    );
  }

  if (data.returnCode && !contains(SUCCESS_CODES, data.returnCode)) {
    return new Errors.XHRError('XHR request returned an error', xhr.status, xhr.statusText, data);
  }

  if (data.error) {
    return new Errors.XHRError('XHR request returned an error', xhr.status, xhr.statusText, data.error);
  }

  return data;
};

export const cloneRequestConfig = function(config: RequestConfig.RequestConfig = {}): RequestConfig.RequestConfig {
  const { data } = config;

  const clonedConfig = clone(config);

  if (data) {
    clonedConfig.data = data;
  }

  return clonedConfig;
};

export const getJSON = function(url: string, config: RequestConfig.RequestConfig = {}): Promise<any> {
  const clonedConfig = cloneRequestConfig(config);

  return ajax(HTTP_GET, url, clonedConfig);
};

export const getBlob = function(url: string, config: RequestConfig.RequestConfig = {}): Promise<any> {
  const clonedConfig = cloneRequestConfig(config);
  clonedConfig.responseType = 'blob';

  return ajax(HTTP_GET, url, clonedConfig);
};

export const getText = function(url: string, config: RequestConfig.RequestConfig = {}): Promise<string> {
  const clonedConfig = cloneRequestConfig(config);
  clonedConfig.responseType = 'text';

  return ajax(HTTP_GET, url, clonedConfig);
};

export const getDocument = function(url: string, config: RequestConfig.RequestConfig = {}): Promise<Document> {
  const clonedConfig = cloneRequestConfig(config);
  clonedConfig.responseType = 'document';

  return ajax(HTTP_GET, url, clonedConfig);
};

export const post = function(url: string, config?: RequestConfig.RequestConfig): Promise<any> {
  const clonedConfig = cloneRequestConfig(config);

  return ajax(HTTP_POST, url, clonedConfig);
};

export const deleteHttp = function(url: string, config: RequestConfig.RequestConfig = {}): Promise<any> {
  const clonedConfig = cloneRequestConfig(config);
  clonedConfig.data = clonedConfig.data || {};

  return ajax(HTTP_DELETE, url, clonedConfig);
};

export const put = function(url: string, config?: RequestConfig.RequestConfig): Promise<any> {
  const clonedConfig = cloneRequestConfig(config);

  return ajax(HTTP_PUT, url, clonedConfig);
};
