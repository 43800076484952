import { deleteHttp, getJSON, post, put } from './xhr';
import { encodeUriQuery } from '../services/util';
import { getConfig } from './servicesConfig';
import { ProductCatalog } from '../typings/ProductCatalog';
import Promise from '../promise';

export const getSupplierProducts = function(
  supplierCode: string,
  queryParams: ProductCatalog.ProductsSupplierQuery
): Promise<ProductCatalog.ApiProductsSupplierResponse> {
  const apiUrl = getConfig().PRODUCT_CATALOG_PRODUCTS_SUPPLIER_ADDRESS;
  const url = `${apiUrl}/${supplierCode}?${encodeUriQuery(queryParams)}`;

  return getJSON(url);
};

export const getSingleSupplierProduct = function(productCode: string): Promise<ProductCatalog.ApiProductSupplier> {
  const apiUrl = getConfig().PRODUCT_CATALOG_PRODUCTS_SUPPLIER_ADDRESS;
  const url = `${apiUrl}/product/${productCode}`;

  return getJSON(url);
};

export const putSupplierProduct = function(
  productCode: string,
  updateQuery: ProductCatalog.ApiUpdateProductQuery
): Promise<ProductCatalog.ApiProductSupplier> {
  const apiUrl = getConfig().PRODUCT_CATALOG_PRODUCTS_SUPPLIER_ADDRESS;
  const url = `${apiUrl}/${productCode}`;

  return put(url, { data: updateQuery });
};

export const getChangedDeadline = function(productCode: string): Promise<ProductCatalog.ApiChangedDeadline> {
  const apiUrl = getConfig().PRODUCT_CATALOG_PRODUCTS_CHANGED_DEADLINES_ADDRESS;
  const url = `${apiUrl}/${productCode}`;

  return getJSON(url);
};

export const postChangedDeadline = function(
  queryParams: ProductCatalog.ApiChangedDeadlineQuery
): Promise<ProductCatalog.ApiChangedDeadline> {
  const apiUrl = getConfig().PRODUCT_CATALOG_PRODUCTS_CHANGED_DEADLINES_ADDRESS;

  return post(apiUrl, { data: queryParams });
};

export const deleteChangedDeadline = function(productCode: string): Promise<void> {
  const apiUrl = getConfig().PRODUCT_CATALOG_PRODUCTS_CHANGED_DEADLINES_ADDRESS;
  const url = `${apiUrl}/${productCode}`;

  return deleteHttp(url);
};

export const getExecutiveOfficers = function(productCode: string): Promise<ProductCatalog.ApiExecutiveOfficers> {
  const apiUrl = getConfig().PRODUCT_CATALOG_PRODUCTS_SUPPLIER_ADDRESS;
  const url = `${apiUrl}/executiveOfficers/${productCode}`;

  return getJSON(url);
};

export const putExecutiveOfficers = function(
  productCode: string,
  responsibles: ProductCatalog.ApiExecutiveOfficers
): Promise<ProductCatalog.ApiExecutiveOfficers> {
  const apiUrl = getConfig().PRODUCT_CATALOG_PRODUCTS_SUPPLIER_ADDRESS;
  const url = `${apiUrl}/executiveOfficers/${productCode}`;

  return put(url, { data: responsibles });
};

export const getProductParameter = function(parameterKey: string, productCode: string): Promise<string> {
  const url = `${getConfig().PRODUCT_PARAMETERS_ADDRESS}?key=${parameterKey}&productCode=${productCode}`;

  return getJSON(url)
    .then(parameter => (parameter ? parameter.value : ''))
    .catch(() => '');
};

export const getProductUsers = function(
  productCode: string,
  logonIdent: string
): Promise<ProductCatalog.ApiProductUsers> {
  const url = `${getConfig().PRODUCT_USERS_ADDRESS}?productKey=${productCode}&username=${logonIdent}&limit=999`;

  return getJSON(url);
};
