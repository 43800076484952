import { getJSON } from './xhr';
import { getConfig } from './servicesConfig';
import Promise from '../promise';
import { clone } from '../services/util';
import { Ping } from '../typings/Ping';
import { RequestConfig } from '../typings/RequestConfig';

const availableServices: Ping.AvailableRestServices = {
  authentication: true,
  product_catalog: true,
  shopping: true
};

const requestConfig: RequestConfig.RequestConfig = {
  noAuth: true
};

/**
 * Check if a service is available
 * @param serviceUrl - The url of the service to ping
 * @returns {any} Returns a promise that resolves to a boolean
 */
const checkServiceAvailability = function(serviceUrl: string): Promise<boolean> {
  return getJSON(serviceUrl, requestConfig)
    .then(({ online }: Ping.Data) => online)
    .catch(() => false);
};

const checkAuthenticationAvailability = function(): Promise<boolean> {
  return checkServiceAvailability(getConfig().PING_ADDRESSES.AUTHENTICATION);
};

const checkProductCatalogAvailability = function(): Promise<boolean> {
  return checkServiceAvailability(getConfig().PING_ADDRESSES.PRODUCT_CATALOG);
};

const checkShoppingAvailability = function(): Promise<boolean> {
  return checkServiceAvailability(getConfig().PING_ADDRESSES.SHOPPING);
};

export const isServiceAvailable = function(service: string): boolean {
  return availableServices[service];
};

/**
 * Check the availability of all the services
 */
export const checkAvailability = function(): Promise<Ping.AvailableRestServices> {
  return Promise.all([
    checkAuthenticationAvailability(),
    checkShoppingAvailability(),
    checkProductCatalogAvailability()
  ]).then(results => {
    const [authentication, shopping, productCatalog] = results;
    availableServices.authentication = authentication;
    availableServices.shopping = shopping;
    availableServices.product_catalog = productCatalog;

    return clone(availableServices);
  });
};
