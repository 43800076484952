import { getConfig } from './servicesConfig';
import { getJSON } from './xhr';
import { RequestConfig } from '../typings/RequestConfig';
import { Token } from '../typings/Token';
import { User } from '../typings/User';
import { ValidateTokenResponse } from '../typings/ValidateTokenResponse';
import * as util from '../services/util';
import * as xhr from './xhr';
import Promise from '../promise';

export const getUserData = function(): Promise<User.ApiUser> {
  return getTokenUser().then(
    (tokenValidationResponse): Promise<any> => {
      if (tokenValidationResponse.user) {
        return xhr.getJSON(tokenValidationResponse.user.links.self.href);
      }
    }
  );
};

export const getSupplierData = function(supplierId: string): Promise<User.Organization> {
  return xhr.getJSON(`${getConfig().AUTHENTICATION_ORGANISATIONS}/${supplierId}`);
};

export const getSupplierMessages = function(supplierId: string): Promise<User.SupplierMessages> {
  const query = util.encodeUriQuery({ userid: supplierId });

  return xhr.getJSON(`${getConfig().AUTHENTICATION_SUPPLIER_MESSAGES}?${query}`);
};

export const getTokenUser = function(): Promise<ValidateTokenResponse.Response> {
  return xhr.getJSON(getConfig().LOGIN_ACCESS_TOKEN);
};

export const login = function(username: string, password: string, clientId?: string): Promise<Token.ApiToken> {
  const data = {
    client_id: clientId || getConfig().CLIENT_ID,
    grant_type: 'password',
    password,
    username
  };

  const requestConfig: RequestConfig.RequestConfig = {
    data: util.encodeUriQuery(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    noAuth: true
  };

  return xhr.post(getConfig().LOGIN_ACCESS_TOKEN, requestConfig);
};

export const getUsers = function(queryParams?: User.UsersQuery): Promise<User.ApiUsersResponse> {
  const defaultParams: User.UsersQuery = {
    includeddeleted: false,
    limit: 10,
    offset: 0
  };
  queryParams = typeof queryParams === 'undefined' ? defaultParams : queryParams;
  const apiUrl = getConfig().AUTHENTICATION_USERS;
  const url = `${apiUrl}?${util.encodeUriQuery(queryParams)}`;

  return getJSON(url);
};
