import Promise from '../promise';
import { encodeUriQuery } from '../services/util';
import { Asset } from '../typings/Asset';
import { OrderData } from '../typings/OrderData';
import { OrderMessage } from '../typings/OrderMessage';
import { Supplier } from '../typings/Supplier';
import { User } from '../typings/User';
import { getConfig } from './servicesConfig';
import { getAccessToken } from './token';
import { deleteHttp, getJSON, post, put } from './xhr';

export const getSupplierOrders = function(
  queryParams: Supplier.SupplierOrdersQuery
): Promise<Supplier.ApiSupplierOrdersResponse> {
  const apiUrl = getConfig().SHOPPING_SUPPLIERORDERS_ADDRESS;
  const url = `${apiUrl}?${encodeUriQuery(queryParams)}`;

  return getJSON(url);
};

export const getSupplierOrderURL = function(orderId: number): string {
  return `${getConfig().SHOPPING_SUPPLIERORDERS_ADDRESS}/${orderId}`;
};

export const getSupplierOrder = function(orderId: number): Promise<Supplier.ApiSingleSupplierOrder> {
  return getJSON(getSupplierOrderURL(orderId));
};

export const deleteSupplierOrder = function(orderId: number): Promise<Supplier.ApiSingleSupplierOrder> {
  return deleteHttp(getSupplierOrderURL(orderId));
};

export const finishSupplierOrder = function(orderId: number): Promise<Supplier.ApiSingleSupplierOrder> {
  const updatedOrderData = {
    status: 'FINISHED'
  };

  return put(getSupplierOrderURL(orderId), { data: updatedOrderData });
};

export const updateOrderData = function(
  orderId: number,
  orderData: OrderData.Data
): Promise<Supplier.ApiSingleSupplierOrder> {
  const updatedOrderData = { orderData };

  return put(getSupplierOrderURL(orderId), { data: updatedOrderData });
};

export const getSupplierOrderLineURL = function(orderId: number, product: string): string {
  return `${getConfig().SHOPPING_SUPPLIERORDERS_ADDRESS}/${orderId}/supplierorderlines/${product}`;
};

export const getSupplierOrderLine = function(orderId: number, product: string): Promise<Supplier.SupplierOrderLine> {
  return getJSON(getSupplierOrderLineURL(orderId, product));
};

export const getSupplierOrdersForUser = function(
  logonIdent: string,
  queryParams: Supplier.SupplierOrdersQuery
): Promise<Supplier.ApiSupplierOrdersResponse> {
  const url = `${getConfig().SHOPPING_SUPPLIERORDERS_USER_ADDRESS}/${logonIdent}?${encodeUriQuery(queryParams)}`;

  return getJSON(url);
};

export const fetchOrderMessages = function(
  orderId: string,
  offset: number = 0,
  limit: number = 100,
  sort: string = 'created'
): Promise<OrderMessage.ApiOrderMessages> {
  const apiUrl = getConfig().SHOPPING_ORDER_MESSAGES_SUPPLIER_ADDRESS;
  const url = `${apiUrl}?offset=${offset}&limit=${limit}&sort=${sort}&order=${orderId}`;

  return getJSON(url);
};

export const postOrderMessage = function(orderId: string, content: string): Promise<OrderMessage.ApiOrderMessage> {
  const apiUrl = getConfig().SHOPPING_ORDER_MESSAGES_SUPPLIER_ADDRESS;
  const data = { content, orderId };

  return post(apiUrl, { data });
};

export const setOrderMessagesRead = function(
  idList: number[],
  newState: boolean = true
): Promise<OrderMessage.ApiOrderMessagesReadStatus> {
  const apiUrl = getConfig().SHOPPING_ORDER_MESSAGES_SUPPLIER_READ_ADDRESS;
  const data = {
    idList,
    newState
  };

  return post(apiUrl, { data });
};

export const getSupplierSettings = function(organizationCode: string): Promise<User.ApiSupplierSettings> {
  const apiUrl = getConfig().AUTHENTICATION_SUPPLIER_SETTINGS;
  const url = `${apiUrl}/${organizationCode}`;

  return getJSON(url);
};

export const putSupplierSettings = function(
  organizationCode: string,
  settings: User.ApiSupplierSettings
): Promise<User.ApiSupplierSettings> {
  const apiUrl = getConfig().AUTHENTICATION_SUPPLIER_SETTINGS;
  const url = `${apiUrl}/${organizationCode}`;

  return put(url, { data: settings });
};

export const generateSupplierAssetDownloadLink = function(assetId: string): Promise<string> {
  return Promise.resolve(getAccessToken()).then(
    accessToken => `${getConfig().SHOPPING_SUPPLIERASSETS_ADDRESS}/${assetId}?authorization=${accessToken}`
  );
};

export const generateSupplierZipDownloadLink = function(orderId: string): Promise<string> {
  return Promise.resolve(getAccessToken()).then(
    accessToken => `${getConfig().SHOPPING_SUPPLIERASSETS_ADDRESS}/${orderId}/order?authorization=${accessToken}`
  );
};

export const getSupplierAssetValidationLink = function(assetId: string): string {
  return `${getConfig().SHOPPING_SUPPLIERASSETS_ADDRESS}/${assetId}/validate`;
};

export const getSupplierZipValidationLink = function(orderId: string): string {
  return `${getConfig().SHOPPING_SUPPLIERASSETS_ADDRESS}/${orderId}/order/validate`;
};

export const uploadAsset = function(
  orderId: number,
  productCode: string,
  asset: FormData
): Promise<Asset.ApiSupplierAsset> {
  let url = `${getConfig().SHOPPING_SUPPLIERASSETS_ADDRESS}/${orderId}`;

  if (productCode) {
    url += `?productcode=${productCode}`;
  }

  return post(url, { data: asset });
};

export const deleteAsset = function(assetId: string): Promise<void> {
  const url = `${getConfig().SHOPPING_SUPPLIERASSETS_ADDRESS}/${assetId}`;

  return deleteHttp(url);
};

export const updateOrderLineStatus = function(
  orderId: number,
  productCode: string,
  status: string
): Promise<Supplier.SupplierOrderLine> {
  return put(getSupplierOrderLineURL(orderId, productCode), { data: { status } });
};

export const resendOrderLine = function(orderId: number, productCode: string): Promise<Supplier.SupplierOrderLine> {
  const url = `${getSupplierOrderLineURL(orderId, productCode)}/resend`;

  return put(url, { data: {} });
};

export const updateExecutiveOfficer = function(
  orderId: number,
  productCode: string,
  logonIdent: string
): Promise<Supplier.SupplierOrderLine> {
  const orderLineUpdate = {
    executiveOfficer: { logonIdent }
  };

  return put(getSupplierOrderLineURL(orderId, productCode), { data: orderLineUpdate });
};

export const finishOrderLineWithNoFiles = function(
  orderId: number,
  productCode: string,
  noAssetsReasonMessage: string
): Promise<Supplier.SupplierOrderLine> {
  const data = {
    status: 'FINISHED',
    noAssetsReasonMessage,
    noFiles: true
  };

  return put(getSupplierOrderLineURL(orderId, productCode), { data });
};

export const createOrderLineDocumentFromSchema = function(
  orderId: number,
  productCode: string,
  schemaValues: any
): Promise<Supplier.SupplierOrderLine> {
  const url = `${getSupplierOrderLineURL(orderId, productCode)}/schema`;

  return post(url, { data: schemaValues });
};

export const updateFinishedByResponsible = function(
  orderId: number,
  productCode: string,
  finishedByResponsible: boolean
): Promise<Supplier.SupplierOrderLine> {
  const orderLineUpdate = { finishedByResponsible };

  return put(getSupplierOrderLineURL(orderId, productCode), { data: orderLineUpdate });
};
