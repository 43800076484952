import * as util from './util';
import { DateTimeFormats, Locale } from '../locales/locale';

function timezoneToOffset(timezone: string, fallback: number): number {
  const requestedTimezoneOffset = Date.parse(`Jan 01, 1970 00:00:00 ${timezone}`) / 60000;

  return isNaN(requestedTimezoneOffset) ? fallback : requestedTimezoneOffset;
}

function addDateMinutes(inputDate: Date, minutes: number): Date {
  inputDate = new Date(inputDate.getTime());
  inputDate.setMinutes(inputDate.getMinutes() + minutes);

  return inputDate;
}

function convertTimezoneToLocal(inputDate: Date, timezone: string, reverse: boolean): Date {
  const reverseMultiplier = reverse ? -1 : 1;
  const timezoneOffset = timezoneToOffset(timezone, inputDate.getTimezoneOffset());

  return addDateMinutes(inputDate, reverseMultiplier * (timezoneOffset - inputDate.getTimezoneOffset()));
}

function padNumber(num: number | string, digits: number, trim?: boolean): string {
  let neg = '';

  if (num < 0) {
    neg = '-';
    num = -num;
  }

  num = String(num);

  while (num.length < digits) {
    num = `0${num}`;
  }

  if (trim) {
    num = num.substr(num.length - digits);
  }

  return neg + num;
}

function dateGetter(name: string, size: number, offset?: number, trim?: boolean): (inputDate: Date) => string {
  offset = offset || 0;

  return function(inputDate: Date): string {
    let value = inputDate[`get${name}`]();

    if (offset > 0 || value > -offset) {
      value += offset;
    }

    if (value === 0 && offset === -12) {
      value = 12;
    }

    return padNumber(value, size, trim);
  };
}

function dateStrGetter(name: string, shortForm?: boolean): (inputDate: Date, formats: DateTimeFormats) => string {
  return function(inputDate: Date, formats: DateTimeFormats): string {
    const value = inputDate[`get${name}`]();
    const dayName = util.uppercase(shortForm ? `SHORT${name}` : name);

    return formats[dayName][value];
  };
}

function timeZoneGetter(_0: Date, _1: DateTimeFormats, offset: number): string {
  const zone = -1 * offset;
  let paddedZone = zone >= 0 ? '+' : '';

  paddedZone += padNumber(Math[zone > 0 ? 'floor' : 'ceil'](zone / 60), 2) + padNumber(Math.abs(zone % 60), 2);

  return paddedZone;
}

function getFirstThursdayOfYear(year: number): Date {
  // 0 = index of January
  const dayOfWeekOnFirst = new Date(year, 0, 1).getDay();

  // 4 = index of Thursday (+1 to account for 1st = 5)
  // 11 = index of *next* Thursday (+1 account for 1st = 12)
  return new Date(year, 0, (dayOfWeekOnFirst <= 4 ? 5 : 12) - dayOfWeekOnFirst);
}

function getThursdayThisWeek(datetime: Date): Date {
  // 4 = index of Thursday
  return new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate() + (4 - datetime.getDay()));
}

function weekGetter(size: number): (inputDate: Date) => string {
  return function(inputDate: Date): string {
    const firstThurs = getFirstThursdayOfYear(inputDate.getFullYear());
    const thisThurs = getThursdayThisWeek(inputDate);

    const diff = +thisThurs - +firstThurs;
    const result = 1 + Math.round(diff / 6.048e8); // 6.048e8 ms per week

    return padNumber(result, size);
  };
}

function ampmGetter(inputDate: Date, formats: DateTimeFormats): string {
  return inputDate.getHours() < 12 ? formats.AMPMS[0] : formats.AMPMS[1];
}

function eraGetter(inputDate: Date, formats: DateTimeFormats): string {
  return inputDate.getFullYear() <= 0 ? formats.ERAS[0] : formats.ERAS[1];
}

function longEraGetter(inputDate: Date, formats: DateTimeFormats): string {
  return inputDate.getFullYear() <= 0 ? formats.ERANAMES[0] : formats.ERANAMES[1];
}

/* eslint-disable sort-keys */
const DATE_FORMATS = {
  yyyy: dateGetter('FullYear', 4),
  yy: dateGetter('FullYear', 2, 0, true),
  y: dateGetter('FullYear', 1),
  MMMM: dateStrGetter('Month'),
  MMM: dateStrGetter('Month', true),
  MM: dateGetter('Month', 2, 1),
  M: dateGetter('Month', 1, 1),
  dd: dateGetter('Date', 2),
  d: dateGetter('Date', 1),
  HH: dateGetter('Hours', 2),
  H: dateGetter('Hours', 1),
  hh: dateGetter('Hours', 2, -12),
  h: dateGetter('Hours', 1, -12),
  mm: dateGetter('Minutes', 2),
  m: dateGetter('Minutes', 1),
  ss: dateGetter('Seconds', 2),
  s: dateGetter('Seconds', 1),
  // while ISO 8601 requires fractions to be prefixed with `.` or `,`
  // we can be just safely rely on using `sss` since we currently don't support single or two digit fractions
  sss: dateGetter('Milliseconds', 3),
  EEEE: dateStrGetter('Day'),
  EEE: dateStrGetter('Day', true),
  a: ampmGetter,
  Z: timeZoneGetter,
  ww: weekGetter(2),
  w: weekGetter(1),
  G: eraGetter,
  GG: eraGetter,
  GGG: eraGetter,
  GGGG: longEraGetter
};
/* eslint-enable sort-keys */

const DATE_FORMATS_SPLIT = /((?:[^yMdHhmsaZEwG']+)|(?:'(?:[^']|'')*')|(?:E+|y+|M+|d+|H+|h+|m+|s+|a|Z|G+|w+))(.*)/;
const NUMBER_STRING = /^-?\d+$/;

export default function date(
  locale: Locale,
  inputDate: string | number | Date,
  format?: string,
  timezone?: string
): string {
  let text = '';
  let parts = [];
  let fn;
  let match;

  format = format || 'mediumDate';
  format = locale.DATETIME_FORMATS[format] || format;

  if (util.isString(inputDate)) {
    inputDate = NUMBER_STRING.test(inputDate) ? util.toInt(inputDate) : util.jsonStringToDate(inputDate);
  }

  if (util.isNumber(inputDate)) {
    inputDate = new Date(inputDate);
  }

  if (!util.isDate(inputDate) || !isFinite(inputDate.getTime())) {
    return String(inputDate);
  }

  while (format) {
    match = DATE_FORMATS_SPLIT.exec(format);

    if (match) {
      parts = parts.concat(match.slice(1));
      format = parts.pop();
    } else {
      parts.push(format);
      format = null;
    }
  }

  let dateTimezoneOffset = inputDate.getTimezoneOffset();

  if (timezone) {
    dateTimezoneOffset = timezoneToOffset(timezone, inputDate.getTimezoneOffset());
    inputDate = convertTimezoneToLocal(inputDate, timezone, true);
  }

  const len = parts.length;

  for (let i = 0; i < len; i++) {
    const value = parts[i];
    fn = DATE_FORMATS[value];
    text += fn
      ? fn(inputDate, locale.DATETIME_FORMATS, dateTimezoneOffset)
      : value.replace(/(^'|'$)/g, '').replace(/''/g, `'`);
  }

  return text;
}
