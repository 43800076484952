import { getConfig } from './servicesConfig';
import Promise from '../promise';
import { getJSON } from './xhr';
import { ProductCatalog } from '../typings/ProductCatalog';

export const getProductBySupplierCode = function(
  supplierCode: string
): Promise<ProductCatalog.ApiProductsSupplierResponse> {
  const apiUrl = getConfig().PRODUCT_CATALOG_LOGISTICS_PRODUCTS_SUPPLIER_ADDRESS;
  const url = `${apiUrl}/${supplierCode}`;

  return getJSON(url);
};
