import Promise from '../promise';
import { OrderMessage } from '../typings/OrderMessage';
import { getConfig } from './servicesConfig';
import { getAccessToken } from './token';
import { getJSON, post } from './xhr';

export const fetchOrderMessages = function(
  orderId: string,
  offset: number = 0,
  limit: number = 100,
  sort: string = 'created'
): Promise<OrderMessage.ApiOrderMessages> {
  const apiUrl = getConfig().SHOPPING_ORDER_MESSAGES_ADDRESS;
  const url = `${apiUrl}?offset=${offset}&limit=${limit}&sort=${sort}&order=${orderId}`;

  return getJSON(url);
};

export const postOrderMessage = function(orderId: string, content: string): Promise<OrderMessage.ApiOrderMessage> {
  const apiUrl = getConfig().SHOPPING_ORDER_MESSAGES_ADDRESS;
  const data = { content, orderId };

  return post(apiUrl, { data });
};

export const setOrderMessagesRead = function(
  idList: number[],
  newState: boolean = true
): Promise<OrderMessage.ApiOrderMessagesReadStatus> {
  const apiUrl = getConfig().SHOPPING_ORDER_MESSAGES_READ_ADDRESS;
  const data = {
    idList,
    newState
  };

  return post(apiUrl, { data });
};

export const generateAssetDownloadLink = function(assetId: string): Promise<string> {
  return Promise.resolve(getAccessToken()).then(
    accessToken => `${getConfig().SHOPPING_ASSETS_ADDRESS}/${assetId}?authorization=${accessToken}`
  );
};

export const generateZipDownloadLink = function(orderId: string): Promise<string> {
  return Promise.resolve(getAccessToken()).then(
    accessToken => `${getConfig().SHOPPING_ASSETS_ADDRESS}/${orderId}/order?authorization=${accessToken}`
  );
};

export const getAssetValidationLink = function(assetId: string): string {
  return `${getConfig().SHOPPING_ASSETS_ADDRESS}/${assetId}/validate`;
};

export const getZipValidationLink = function(orderId: string): string {
  return `${getConfig().SHOPPING_ASSETS_ADDRESS}/${orderId}/order/validate`;
};
