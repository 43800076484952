import axios from 'axios';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { coreActions } from '@ambita/ambita-components-core';

serviceWorker.unregister();

const container = document.getElementById('root');
const root = createRoot(container);

axios.get('config.json').then(response => {
  if (response.data && response.data.env) {
    coreActions.changeSettings({
      environment: response.data.env
    })
  } else {
    throw Error('Malformed config.json; missing required property "env"');
  }
  root.render(<App/>);
}).catch(error => {
  console.error(error)
  console.warn('Environment cannot be determined; fallback to dev')
  coreActions.changeSettings({
    environment: 'dev'
  });
  root.render(<App/>);
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
