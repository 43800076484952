import * as util from './util';
import inMemoryStorage from './inMemoryStorage';
import { getSettings } from './settingsService';

const isStorageAvailable = function(type: string): boolean {
  try {
    const localStorage = window[type];
    const x = '___storage_test___';
    localStorage.setItem(x, x);
    localStorage.removeItem(x);

    return true;
  } catch (e) {
    return false;
  }
};

let storage: Storage;

if (isStorageAvailable('localStorage')) {
  storage = window.localStorage;
} else if (isStorageAvailable('sessionStorage')) {
  storage = window.sessionStorage;
} else {
  storage = inMemoryStorage;
}

const KEY_PREFIX = 'infoland-';

const getKeyName = function(name: string): string {
  const { storageVersion, storageNamespace, storageNamespaceVersion } = getSettings();
  const keyParts = [KEY_PREFIX];

  if (!isNaN(storageVersion)) {
    keyParts.push(`v${storageVersion}__`);
  }

  if (storageNamespace) {
    keyParts.push(`${storageNamespace}-`);
  }

  if (storageNamespace && !isNaN(storageNamespaceVersion)) {
    keyParts.push(`v${storageNamespaceVersion}__`);
  }

  return keyParts.concat(name).join('');
};

/**
 * @description Date reviver used for converting date strings into Date objects when parsing JSON.
 * Works only for ISO compliant date strings
 * @param _ JSON entity key
 * @param value JSON entity value
 * @returns {any} Parsed value for provided entity
 */
const dateReviver = function(_: string, value: string): string | Date {
  if (util.isString(value)) {
    return util.jsonStringToDate(value);
  }

  return value;
};

/**
 * @description Saves item into storage
 * @param key - Item key
 * @param item - Item value. If value is object it will be converted into JSON string
 */
export const save = function(key: string, item: any): void {
  if (util.isObject(item)) {
    item = JSON.stringify(item);
  }

  storage.setItem(getKeyName(key), item);
};

/**
 * @description Retrieves item from storage
 * @param key - Item key
 * @param reviveDates - if set to true date strings will be converted into Date objects
 * @returns {any} parsed key value
 */
export const retrieve = function(key: string, reviveDates?: boolean): any {
  const item = storage.getItem(getKeyName(key));

  return reviveDates ? JSON.parse(item, dateReviver) : JSON.parse(item);
};

/**
 * @description Removes item from storage
 * @param key
 */
export const remove = function(key: string): void {
  storage.removeItem(getKeyName(key));
};

/**
 * @description Remove all items from storage that have keys without version
 * or version that is smaller than version specified in arguments
 * @param {number} version Current version
 */
export const clearOutdatedItems = function(version: number): void {
  const versionedKeyRegExp = new RegExp(`^${KEY_PREFIX}(v(\\d+)__)?`);
  const storageLength = storage.length;
  const keysToRemove = [];

  for (let i = 0; i < storageLength; i++) {
    const key = storage.key(i);
    const match = key.match(versionedKeyRegExp);

    if (match) {
      const parsedVersion = parseInt(match[2], 10);
      const keyVersion = isNaN(parsedVersion) ? -1 : parsedVersion;

      if (keyVersion < version) {
        keysToRemove.push(key);
      }
    }
  }

  keysToRemove.forEach(key => storage.removeItem(key));
};

/**
 * @description Remove all items from storage that belong to specified namespace and have no version or version
 * that is smaller than version specified in arguments
 * @param {string} namespace Storage namespace
 * @param {number} version Current version
 */
export const clearOutdatedNamespaceItems = function(namespace: string, version: number): void {
  const namespaceKeyRegExp = new RegExp(`^${KEY_PREFIX}(v\\d+__)?${namespace}-(v(\\d+)__)?`);
  const storageLength = storage.length;
  const keysToRemove = [];

  for (let i = 0; i < storageLength; i++) {
    const key = storage.key(i);
    const match = key.match(namespaceKeyRegExp);

    if (match) {
      const parsedVersion = parseInt(match[3], 10);
      const keyVersion = isNaN(parsedVersion) ? -1 : parsedVersion;

      if (keyVersion < version) {
        keysToRemove.push(key);
      }
    }
  }

  keysToRemove.forEach(key => storage.removeItem(key));
};
