import * as BluebirdPromise from 'bluebird';

BluebirdPromise.config({
  cancellation: true,
  warnings: {
    wForgottenReturn: false
  }
});

export default BluebirdPromise;
