let transitionFeatureFlag;

export const hasTransitionFeature = function(): boolean {
  if (transitionFeatureFlag !== undefined) {
    return transitionFeatureFlag;
  }

  const transitionProperties = ['transition', 'WebkitTransition', 'MozTransition', 'msTransition', 'OTransition'];
  const domElement = document.createElement('div');

  return (transitionFeatureFlag = transitionProperties.some(property => domElement.style[property] !== undefined));
};
