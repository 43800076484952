import { Locale } from '../locales/locale';
import { merge } from './util';
import enLocale from '../locales/en-us';
import nbLocale from '../locales/nb';

export interface AvailableLocales {
  [key: string]: Locale;
  'en-us': Locale;
  nb: Locale;
}

export interface AvailableLanguages {
  [key: string]: Language;
  'en-us': Language;
  nb: Language;
}

export interface Language {
  id: string;
  locale: string;
  name: string;
}

const availableLocales: AvailableLocales = {
  'en-us': enLocale,
  nb: nbLocale
};

const availableLanguages: AvailableLanguages = {
  'en-us': {
    id: 'en-us',
    locale: 'en-us',
    name: 'English'
  },
  nb: {
    id: 'nb',
    locale: 'nb',
    name: 'Norsk bokmål'
  }
};

let selectedLocale: Locale = availableLocales.nb;
let selectedLanguage: Language = availableLanguages.nb;

const availableTranslations = {
  'en-us': {},
  nb: {}
};

/**
 * Register new locale or override existing locale with new properties
 * @param locale Object with locale properties
 */
export const registerLocale = function(locale: Locale): void {
  availableLocales[locale.id] = locale;
};

/**
 * Register new language or override existing language config with new properties.
 * When existing language config is overridden all translations for specified language are preserved.
 * @param languageConfig Object with language properties
 */
export const registerLanguage = function(languageConfig: Language): void {
  availableLanguages[languageConfig.id] = languageConfig;

  if (!availableTranslations.hasOwnProperty(languageConfig.id)) {
    availableTranslations[languageConfig.id] = {};
  }
};

/**
 * Register translations for specific component.
 * If translations for specified component and specified language are already registered
 * new and existing translations will be merged,
 * when both translations have value for the same key new translations override existing ones
 * @param componentId Unique component identifier
 * @param translations Hash map with component translations
 */
export const registerTranslations = function(componentId: string, translations: any): void {
  Object.keys(translations).forEach(languageId => {
    const languageTranslations = availableTranslations[languageId];

    if (!languageTranslations) {
      throw Error(`Cannot register component translations, language "${languageId}" not registered`);
    }

    if (languageTranslations.hasOwnProperty(componentId)) {
      merge(languageTranslations[componentId], translations[languageId]);
    } else {
      languageTranslations[componentId] = translations[languageId];
    }
  });
};

/**
 * Set locale. If specified locale is not available an error will be thrown.
 * @param localeId Locale identifier
 */
export const setLocale = function(localeId: string): void {
  if (!availableLocales.hasOwnProperty(localeId)) {
    throw Error(`Locale "${localeId}" is not available. Use "registerLocale" to register additional locales`);
  }

  selectedLocale = availableLocales[localeId];
};

/**
 * Set language and change locale to locale specified in selected language config.
 * If specified language is not available an error will be thrown.
 * @param languageId Language identifier
 */
export const setLanguage = function(languageId: string): void {
  if (!availableLanguages.hasOwnProperty(languageId)) {
    throw Error(`Language "${languageId}" is not available.
      Use "registerLanguage" to register additional languages`);
  }

  selectedLanguage = availableLanguages[languageId];
  setLocale(selectedLanguage.locale);
};

/**
 * Get currently selected locale
 * @returns {Locale} Currently selected locale
 */
export const getCurrentLocale = function(): Locale {
  return selectedLocale;
};

/**
 * Get currently selected language
 * @returns {Language} Currently selected language
 */
export const getCurrentLanguage = function(): Language {
  return selectedLanguage;
};

/**
 * Get translations for all registered components in currently selected language.
 * If there is no selected language returns an empty object
 * @returns {any} Object containing translations for registered components
 */
export const getCurrentTranslation = function(): any {
  return selectedLanguage ? availableTranslations[selectedLanguage.id] : {};
};

/**
 * Get all registered locales
 * @returns {AvailableLocales} Hash map with available locales
 */
export const getAvailableLocales = function(): AvailableLocales {
  return availableLocales;
};

/**
 * Get all registered languages
 * @returns {AvailableLanguages} Hash map with available languages
 */
export const getAvailableLanguages = function(): AvailableLanguages {
  return availableLanguages;
};

/**
 * Get a specific translation of a component for a given or current language
 * @param componentId Component identifier
 * @param translationKey Translation identifier
 * @param languageId Language identifier
 * @returns {string} Translation of the component in the given or current language
 */
export const getTranslation = function(componentId: string, translationKey: string, languageId?: string): string {
  const translations = languageId ? availableTranslations[languageId] : getCurrentTranslation();

  return translations[componentId] ? translations[componentId][translationKey] : '';
};
