import React from 'react';
import './Footer.modules.scss';
import logo from '../assets/img/ambita-logo-white.svg';
import { Container, Row, Col } from 'react-bootstrap';
import facebook from '../assets/img/facebook_white.svg';
import linkedin from '../assets/img/linkedin_white.svg';
import twitter from '../assets/img/twitter_white.svg';

const Footer = () => {
  return (
    <footer className={'footer'}>
      <Container>
        <Row>
          <Col md={'3'}>
            <img className={'footerLogoImg'} src={logo} alt={'Ambita Logo'}/>
          </Col>
          <Col md={'3'}>
            <h4>Kontakt oss</h4>
            <p>
              <b>(+47) 24 13 35 00</b>
              <br/>
              <b>info@ambita.com</b>
              <br/>
              <br/>
              Dronning Mauds gate 10
              <br/>
              Postboks 2923 Solli
              <br/>
              0250 Oslo
              <br/>
              Norge
            </p>
          </Col>
          <Col md={'3'}>
            <h4>Kundeservice</h4>
            <p>
              <b>(+47) 24 13 35 50</b>
              <br/>
              <b>support@ambita.com</b>
              <br/>
              <br/>
              <a className={'footerLink'}
                 href="https://www.ambita.com/kundeservice/?__hstc=237185119.686a47a749f73f473849e4657c424cb8.1579002625973.1579008307659.1579085096697.3&__hssc=237185119.1.1579085096697&__hsfp=525427987">
                Kundeservice
              </a>
              <br/>
              <a className={'footerLink'}
                 href="https://www.ambita.com/kundeservice/skriv-til-oss/?__hstc=237185119.686a47a749f73f473849e4657c424cb8.1579002625973.1579008307659.1579085096697.3&__hssc=237185119.1.1579085096697&__hsfp=525427987">
                Skriv til oss
              </a>
            </p>
          </Col>
          <Col className={'social'} md={'3'}>
            <h4>Følg oss</h4>
            <a className={'social'} href={'https://www.facebook.com/ambita.no/'}>
              <img className={'socialIcon'} src={facebook} alt={'Facebook icon'}/>
              <span className={'socialText'}>Facebook</span>
            </a>
            <a className={'social'} href={'https://www.linkedin.com/company/ambita'}>
              <img className={'socialIcon'} src={linkedin} alt={'LinkedIn icon'}/>
              <span className={'socialText'}>LinkedIn</span>
            </a>
            <a className={'social'} href={'https://twitter.com/ambitaas'}>
              <img className={'socialIcon'} src={twitter} alt={'Twitter icon'}/>
              <span className={'socialText'}>Twitter</span>
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  )
};

export default Footer;
