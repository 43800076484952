export interface ApiError {
  message?: string;
  returnCode?: Number;
}

export class CustomError extends Error {
  errorData: Object;

  name: string;

  constructor(name?: string, message?: string, errorData?: Object) {
    super();

    if (name) {
      this.name = name;
    }

    if (message) {
      this.message = message;
    }

    if (errorData) {
      this.errorData = errorData;
    }
  }
}

export class XHRError extends Error {
  errorData: ApiError;

  name: string;

  status: number;

  statusText: string;

  constructor(message?: string, xhrStatus: number = -1, xhrStatusText: string = '', errorData?: ApiError) {
    super();
    this.message = message || 'XHR Error';
    this.status = xhrStatus;
    this.statusText = xhrStatusText;

    if (errorData) {
      this.errorData = errorData;
    }
  }
}
