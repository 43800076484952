// region Actions
// Token
export const TOKEN_CHANGE_ACTION = 'TOKEN_CHANGE_ACTION';
export const UPDATE_TOKEN_ACTION = 'UPDATE_TOKEN_ACTION';
export const REMOVE_TOKEN_ACTION = 'REMOVE_TOKEN_ACTION';
export const TOKEN_NOT_FOUND_ACTION = 'TOKEN_NOT_FOUND_ACTION';

// User
export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const UPDATE_USER_ACTION = 'UPDATE_USER_ACTION';
export const LOGIN_SUCCESS_ACTION = 'LOGIN_SUCCESS_ACTION';
export const LOGIN_FAILED_ACTION = 'LOGIN_FAILED_ACTION';
export const LOGIN_SERVICE_UNAVAILABLE_ACTION = 'LOGIN_SERVICE_UNAVAILABLE_ACTION';

// Logger
export const SET_LOGGER_ACTION = 'SET_LOGGER_ACTION';

// Service
export const AVAILABILITY_CHECKED_ACTION = 'AVAILABILITY_CHECKED_ACTION';

// Settings
export const CHANGE_LANGUAGE_ACTION = 'CHANGE_LANGUAGE_ACTION';
export const CHANGE_SETTINGS_ACTION = 'CHANGE_SETTINGS_ACTION';
// endregion

// region Events
// User
export const USER_CHANGE_EVENT = 'USER_CHANGE_EVENT';
export const LOGIN_SUCCESS_EVENT = 'LOGIN_SUCCESS_EVENT';
export const LOGOUT_EVENT = 'LOGOUT_EVENT';
export const LOGIN_FAILED_EVENT = 'LOGIN_FAILED_EVENT';
export const LOGIN_SERVICE_UNAVAILABLE_EVENT = 'LOGIN_SERVICE_UNAVAILABLE_EVENT';

// Token
export const TOKEN_NOT_FOUND_EVENT = 'TOKEN_NOT_FOUND_EVENT';
export const TOKEN_CHANGE_EVENT = 'TOKEN_CHANGE_EVENT';

// Settings
export const SETTINGS_CHANGED_EVENT = 'SETTINGS_CHANGED_EVENT';
export const LANGUAGE_CHANGED_EVENT = 'LANGUAGE_CHANGED_EVENT';

// Service
export const AVAILABILITY_CHECKED_EVENT = 'AVAILABILITY_CHECKED_EVENT';
// endregion
