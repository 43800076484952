/* 
This code will fix the URL if it is missing a question mark before query params
We have to support it since this format of the url is used in invoice-pdf produce by
UniMicro.

http://localhost:3000/invoiceNumber=12000442&accessCode=xxxxxxx >
http://localhost:3000/?invoiceNumber=12000442&accessCode=xxxxxxx

*/

const regexMissingQuestionMark = /(?<!:|\/)(\/)(?!\?|$)/;

const missingQuestionMarkBeforeParams = (url) => {
  return regexMissingQuestionMark.test(url);
}

function replaceSingleSlashWithSlashAndQuestionMark(url) {
  return url.replace(regexMissingQuestionMark, '/?');
}

const fixUrlMissingQuestionMarkBeforeQueryParams = (url) => {
  if (missingQuestionMarkBeforeParams(url)) {
    return replaceSingleSlashWithSlashAndQuestionMark(url)
  }
  return url;
};

export const fixMalformedUrl = () => {
  // Early initialization - can be placed in index.js or App.js
  if (typeof window !== 'undefined') {
    const currentUrl = window.location.href;
    const fixedUrl = fixUrlMissingQuestionMarkBeforeQueryParams(currentUrl);  
    // Only update if the URL needed fixing
    if (currentUrl !== fixedUrl) {
      window.history.replaceState({}, '', fixedUrl);
    }
  }
}