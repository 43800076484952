import { RequestConfig } from '../typings/RequestConfig';
import { Token } from '../typings/Token';
import * as config from './servicesConfig';
import * as util from '../services/util';
import * as xhr from './xhr';
import Promise from '../promise';

export const tokenRefresh = function(refreshToken: string): Promise<Token.ApiToken> {
  const data = {
    grant_type: 'refresh_token',
    refresh_token: refreshToken
  };

  const requestConfig: RequestConfig.RequestConfig = {
    data: util.encodeUriQuery(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    noAuth: true
  };

  return xhr.post(config.getConfig().LOGIN_ACCESS_TOKEN, requestConfig);
};

export const geoTokenRefresh = function(expiration: number): Promise<Token.ApiGeoToken> {
  const queryParams = {
    expiration,
    referer: `${window.location.protocol}//${window.location.host}`
  };
  const url = `${config.getConfig().LOGIN_GEODATA_ADDRESS}?${util.encodeUriQuery(queryParams)}`;

  return xhr.getJSON(url);
};
