/**
 * Detect if you're running version 10 or higher of Internet Explorer
 * @returns {Boolean}
 */
export function IsRunningIE9OrOlder(): boolean {
  for (let i = 9; i > 0; i--) {
    const div = document.createElement('div');

    div.innerHTML = `<!--[if IE ${i} ]><span></span><![endif]-->`;

    if (div.getElementsByTagName('span').length) {
      return true;
    }
  }

  return false;
}
