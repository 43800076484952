import Promise from '../promise';
import { dispatch } from '../dispatcher/dispatcher';
import * as constants from '../constants/core';
import { login as userServiceLogin } from '../dataServices/user';
import { checkAvailability } from '../dataServices/restServiceAvailability';
import { error as logError } from '../services/logService';
import { CoreSettings } from '../typings/CoreSettings';
import { Logger } from '../typings/Logger';
import { remove as removeToken } from '../dataServices/token';
import { Token } from '../typings/Token';
import { XHRError } from '../services/errorService';
import { Errors } from '../typings/Errors';

const updateToken = function(tokenObject: Token.ApiToken): void {
  dispatch({
    actionType: constants.UPDATE_TOKEN_ACTION,
    tokenObject
  });
};

const triggerLoginFailed = function(): void {
  dispatch({
    actionType: constants.LOGIN_FAILED_ACTION
  });
};

const triggerLoginUnavailable = function(): void {
  dispatch({
    actionType: constants.LOGIN_SERVICE_UNAVAILABLE_ACTION
  });
};

const loginErrorHandling = function(error: XHRError): Promise<void> {
  if (error.status >= 500) {
    triggerLoginUnavailable();
  } else {
    triggerLoginFailed();
  }

  logError(error.message, error);

  return Promise.reject(Error('loginError'));
};

export const changeSettings = function(settings: CoreSettings): void {
  dispatch({
    actionType: constants.CHANGE_SETTINGS_ACTION,
    settings
  });

  if (settings.environment) {
    checkServiceAvailability();
  }
};

export const checkServiceAvailability = function(): void {
  checkAvailability().then(availableServices => {
    dispatch({
      actionType: constants.AVAILABILITY_CHECKED_ACTION,
      availableServices
    });
  });
};

let loginPromise: Promise<any>;

export const login = function(username: string, password: string, clientId?: string): Promise<any> {
  if (loginPromise) {
    loginPromise.cancel();
  }

  loginPromise = userServiceLogin(username, password, clientId)
    .then(updateToken)
    .catch(loginErrorHandling);

  return loginPromise;
};

export const loginWithAccessToken = function(accessToken: string): void {
  if (!accessToken) {
    return;
  }

  const tokenObject: Token.ApiToken = {
    access_token: accessToken,
    expires_in: 3600,
    refresh_token: '',
    scope: [],
    token_type: 'Bearer'
  };

  updateToken(tokenObject);
};

export const logout = function(): void {
  removeToken();
};

export const setLogger = function(logger: Logger): void {
  dispatch({
    actionType: constants.SET_LOGGER_ACTION,
    logger
  });
};

/**
 * Create an error action function that can be used in a promise chain.
 *
 * @example
 * ```
 * import { coreActions, dispatcher } from 'ambita-components-core';
 * import { ACTIONS } from '~/constants/<...>';
 *
 * const errorAction = coreActions.createErrorAction(ACTIONS.ERROR);
 * export const actionCreator = (): void => {
 *  return myServiceCall()
 *    .then(() => dispatcher.dispatch({ actionType: ACTIONS.MY_ACTION }))
 *    .catch(errorAction(ACTIONS.MY_ACTION))
 * }
 * ```
 */
export const createErrorAction = (errorAction: string) => (sourceAction: string) => (error: Errors.XHR) =>
  errorHandler(error, errorAction, sourceAction);

export const errorHandler = (error: Errors.XHR, actionType: string, sourceAction: string): void => {
  dispatch({
    actionType,
    error,
    sourceAction
  });
  console.error(error);
};
