import { isProduction } from '../dataServices/servicesConfig';
import coreStore from '../stores/coreStore';

export const info = (message: string, ...args: any[]): void => {
  if (isProduction()) {
    return;
  }

  const logger = coreStore.getLogger();

  if (logger) {
    logger.info(message, args);
  }

  if (window.console && window.console.info) {
    window.console.info(message, ...args);
  }
};

export const warn = (message: string, ...args: any[]): void => {
  const logger = coreStore.getLogger();

  if (logger) {
    logger.warning(message, args);
  }

  if (window.console && window.console.warn) {
    window.console.warn(message, ...args);
  }
};

export const error = (err: string | Error, ...args: any[]): void => {
  let message;

  if (err instanceof Error) {
    ({ message } = err);
    args = [err, ...args];
  } else {
    message = err;
  }

  const logger = coreStore.getLogger();

  if (logger) {
    logger.error(message, ...args);
  }

  if (window.console && window.console.error) {
    window.console.error(message, ...args);
  }
};

/**
 * @description Throws error with printf style formatted message
 * if provided condition evaluates to falsy value
 * @param {any} condition Expression that can be converted into boolean value
 * @param {string} format Error message with printf style (%s) placeholders for additional arguments
 * @param {Array<string>} args Additional arguments that will get inserted into error message
 */
export const invariant = (condition: any, format: string, ...args: string[]): void => {
  if (format == null) {
    throw new Error('Invariant requires an error message argument');
  }

  if (!condition) {
    let invariantError;
    let argIndex = 0;
    invariantError = new Error(`Invariant Violation: ${format.replace(/%s/g, () => args[argIndex++])}`);
    invariantError.framesToPop = 1; // we don't care about invariant's own frame

    throw invariantError;
  }
};
