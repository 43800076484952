import React from 'react';
import styles from './Header.module.scss';
import logo from '../assets/img/ambita_logo_purple.svg';
import { Nav, Navbar, NavbarBrand } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons';

const Header = () => {
  return (
    <header className={styles.header}>
      <Navbar className={`mr-auto ${styles.headerNavbar}`}>
        <NavbarBrand className={styles.headerLogo}>
          <a href={'https://www.ambita.com'} target={'_self'} >
            <img className={styles.headerLogoImg} src={logo} alt="Ambita logo"/>
          </a>
        </NavbarBrand>
        <Nav className={`mr-auto`}>
          <Nav.Link href={'https://www.ambita.com/om-oss/'} target={'_self'} className={styles.headerNavbarLink}>Om oss</Nav.Link>
          <Nav.Link href={'https://www.ambita.com/tjenester/hva-vi-tilbyr/'} target={'_self'} className={styles.headerNavbarLink}>Hva vi tilbyr</Nav.Link>
          <Nav.Link href={'https://www.ambita.com/tjenester/bransjer/'} target={'_self'} className={styles.headerNavbarLink}>Bransjer</Nav.Link>
          <Nav.Link href={'https://www.ambita.com/aktuelt/'} target={'_self'} className={styles.headerNavbarLink}>Aktuelt</Nav.Link>
          <Nav.Link href={'http://www.ambita.com/event/'} target={'_self'} className={styles.headerNavbarLink}>Events</Nav.Link>
          <Nav.Link href={'https://www.ambita.com/kundeservice/'} target={'_self'} className={styles.headerNavbarLink}>Kundesenter</Nav.Link>
          <Nav.Link href={'https://infoland.ambita.com/'} target={'_self'} className={styles.headerNavbarLinkInfoland}><FontAwesomeIcon icon={faShoppingCart} /> Infoland</Nav.Link>
        </Nav>
      </Navbar>
    </header>
  )
};
export default Header;
