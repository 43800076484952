import { EventEmitter } from 'eventemitter3';

export const BROADCAST_EVENT = 'BROADCAST_EVENT';

const eventEmitter = new EventEmitter();

export const broadcast = function(message: string): void {
  eventEmitter.emit(BROADCAST_EVENT, message);
};

export const subscribe = function(fn: (...args: any[]) => void): void {
  eventEmitter.on(BROADCAST_EVENT, fn);
};

export const unsubscribe = function(fn: (...args: any[]) => void): void {
  eventEmitter.removeListener(BROADCAST_EVENT, fn);
};
